// import React from 'react';
// import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

// function VendorSelectionModal({ show, onHide, vendors, onSelectVendor }) {
//   return (
//     <Modal show={show} onHide={onHide} centered>
//       <Modal.Header closeButton>
//         <Modal.Title className="custom-text-center">Please select a vendor and fill the information</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Row>
//           <Col md={6}>
//             <Form.Group>
//               <Form.Control as="select" size="lg" className="custom-vendor-list">
//                 {vendors.map((vendor, index) => (
//                   <option key={index}>{vendor}</option>
//                 ))}
//               </Form.Control>
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group>
//               <Form.Label>Price:</Form.Label>
//               <Form.Control type="text" />
//             </Form.Group>
//             <Form.Group>
//               <Form.Label>Min Order:</Form.Label>
//               <Form.Control type="text" />
//             </Form.Group>
//             <Form.Group>
//               <Form.Label>Shipping cost:</Form.Label>
//               <Form.Control type="text" />
//             </Form.Group>
//           </Col>
//         </Row>
//       </Modal.Body>
//       <Modal.Footer className="justify-content-center">
//         <Button variant="primary" onClick={onSelectVendor}>Select</Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }


// export default VendorSelectionModal;


// import React from 'react';
// import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
// import './ComponentPopup.css';


// function VendorSelectionModal({ show, onHide, vendors, onSelectVendor }) {
//   const handleSelect = (e) => {
//     const selectedVendor = e.target.value;
//     onSelectVendor(selectedVendor);  
//   };

//   return (
//     <Modal show={show} onHide={onHide} centered>
//       <Modal.Header closeButton>
//         <Modal.Title className="custom-text-center">Please select a vendor and fill the information</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Row>
//           <Col md={6}>
//             <Form.Group>
//               <Form.Control as="select" size="lg" className="custom-vendor-list" onChange={handleSelect}>
//                 <option>Select a vendor</option>
//                 {vendors.map((vendor, index) => (
//                   <option key={index} value={vendor}>{vendor}</option>
//                 ))}
//               </Form.Control>
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group>
//               <Form.Label>Price:</Form.Label>
//               <Form.Control type="text" />
//             </Form.Group>
//             <Form.Group>
//               <Form.Label>Min Order:</Form.Label>
//               <Form.Control type="text" />
//             </Form.Group>
//             <Form.Group>
//               <Form.Label>Shipping cost:</Form.Label>
//               <Form.Control type="text" />
//             </Form.Group>
//           </Col>
//         </Row>
//       </Modal.Body>
//       <Modal.Footer className="justify-content-center">
//         <Button variant="primary" onClick={onHide}>Close</Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }

// export default VendorSelectionModal;

// import React, { useState } from 'react';
// import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

// function VendorSelectionModal({ show, onHide, vendors, onSelectVendor }) {
//   const [selectedVendor, setSelectedVendor] = useState('');

//   const handleSelect = (e) => {
//     const vendor = e.target.value;
//     setSelectedVendor(vendor);
//     onSelectVendor(vendor);  // Update the parent component with the selected vendor
//   };

//   return (
//     <Modal show={show} onHide={onHide} centered>
//       <Modal.Header closeButton>
//         <Modal.Title className="custom-text-center">Please select a vendor and fill the information</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Row>
//           <Col md={6}>
//             <Form.Group>
//               <Form.Label>Select Vendor</Form.Label>
//               <Form.Control
//                 as="select"
//                 size="lg"
//                 className="custom-vendor-list"
//                 value={selectedVendor}  // Set value to the selectedVendor state
//                 onChange={handleSelect}
//               >
//                 <option value="">Select a vendor</option>
//                 {vendors.map((vendor, index) => (
//                   <option key={index} value={vendor}>
//                     {vendor}
//                   </option>
//                 ))}
//               </Form.Control>
//             </Form.Group>
//           </Col>
//           <Col md={6}>
//             <Form.Group>
//               <Form.Label>Price:</Form.Label>
//               <Form.Control type="text" />
//             </Form.Group>
//             <Form.Group>
//               <Form.Label>Min Order:</Form.Label>
//               <Form.Control type="text" />
//             </Form.Group>
//             <Form.Group>
//               <Form.Label>Shipping cost:</Form.Label>
//               <Form.Control type="text" />
//             </Form.Group>
//           </Col>
//         </Row>
//       </Modal.Body>
//       <Modal.Footer className="justify-content-center">
//         <Button variant="primary" onClick={onHide}>Close</Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }

// export default VendorSelectionModal;


import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

function VendorSelectionModal({ show, onHide, vendors, onSelectVendor }) {
  const [selectedVendor, setSelectedVendor] = useState('');

  const handleSelect = (e) => {
    const vendor = e.target.value;
    setSelectedVendor(vendor);
    onSelectVendor(vendor);  
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className="custom-text-center">Please select a vendor and fill the information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Select Vendor</Form.Label>
              <Form.Control
                as="select"
                size="lg"
                className="custom-vendor-list"
                value={selectedVendor}  
                onChange={handleSelect}
              >
                <option value="">Select a vendor</option>
                {vendors.map((vendor, index) => (
                  <option key={index} value={vendor}>
                    {vendor}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Price:</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group>
              <Form.Label>Min Order:</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group>
              <Form.Label>Shipping cost:</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={onHide}>Close</Button>  {/* Close button to hide the modal */}
      </Modal.Footer>
    </Modal>
  );
}

export default VendorSelectionModal;
