


// BASE URL API------------------------------------------------------------------
const API_BASE_URL = 'https://api-dev.bessandsolar.com/api';
 const usertoken = localStorage.getItem("userToken"); 
 console.log("idddddddddddddd",usertoken)



// desgin tab listing------------------------------------------------------------

export const fetchSolarDesigns = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/design/get-solar-designs`, {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
          "Authorization":'Bearer '+ usertoken
        },
      });
      const data = await response.json();
      if (Array.isArray(data.result)) {
        return data.result;
      } else {
        console.error('Unexpected data format:', data);
        return [];
      }
    } catch (error) {
      console.error('Error fetching solar designs:', error);
      return [];
    }
  };
  

// component tab listing------------------------------------------------------------

export const fetchComponents = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/component/get-components`, {
      method: 'GET',
      headers: {
        'accept': 'text/plain',
        "Authorization":'Bearer '+ usertoken
      },
    });
    const data = await response.json();
    if (Array.isArray(data.result)) {
      return data.result;
    } else {
      console.error('Unexpected data format:', data);
      return [];
    }
  } catch (error) {
    console.error('Error fetching components:', error);
    return [];
  }
};

// GridTiedWBattery  tab listing ------------------------------------------------------
export const fetchDesignVersions = async (designId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/design/get-design-versions?designId=${designId}`, {
      method: 'GET',
      headers: {
        'accept': 'text/plain',
        "Authorization":'Bearer '+ usertoken
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    if (Array.isArray(data.result)) {
      return data.result;
    } else {
      console.error('Unexpected data format:', data);
      return [];
    }
  } catch (error) {
    console.error('Error fetching design versions:', error);
    return [];
  }
};



// fetchDesignComponents---------------------------------------------------------------------
export const fetchDesignComponents = async (designId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/design/get-design-components?designId=${designId}`, {
      method: 'GET',
      headers: {
        'accept': 'text/plain',
        "Authorization":'Bearer '+ usertoken
      },
    });
    const data = await response.json();
    if (data.message === 'Success.') {
      return data.result; 
    } else {
      console.error('Unexpected response message:', data);
      return [];
    }
  } catch (error) {
    console.error('Error fetching design components:', error);
    return [];
  }
};



// POST API-----------------------------------------------------------------------------------
export const saveDesign = async (designData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/design/post-solar-design`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization":'Bearer '+ usertoken
      },
      body: JSON.stringify(designData),
    });

    if (!response.ok) {
      throw new Error('Failed to save design');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error saving design:', error);
    return null;
  }
};


// putSolarDesign-------------------------------------------------------------------------------
export const putSolarDesign = async (designData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/design/put-solar-design`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',  
        'accept': 'text/plain',
        "Authorization":'Bearer '+ usertoken
      },
      body: JSON.stringify(designData),  
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    
    return await response.json();  
  } catch (error) {
    console.error('Error updating solar design:', error);
    return { message: 'Error updating solar design' };
  }
};




// Fetch component details by ID----------------------------------------------------------------------
export const fetchComponentDetails = async (componentId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/component/get-component-details?componentId=${componentId}`, {
      method: 'GET',
      headers: {
        'accept': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data.result; // Assuming the API response has a 'result' field
  } catch (error) {
    console.error('Error fetching component details:', error);
    return null;
  }
};


// Fetch component types------------------------------------------------------------------------------
export const fetchComponentTypes = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/component/get-component-types`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        "Authorization":'Bearer '+ usertoken
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data.result || [];
  } catch (error) {
    console.error('Error fetching component types:', error);
    return [];
  }
};









//Fetch vendor----------------------------------------------------------------------------------
export const fetchVendors = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/vendor/get-vendors`, {
      method: 'GET',
      headers: {
        'accept': 'text/plain',
        "Authorization":'Bearer '+ usertoken

      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    if (Array.isArray(data.result)) {
      return data.result;
    } else {
      console.error('Unexpected data format:', data);
      return [];
    }
  } catch (error) {
    console.error('Error fetching vendors:', error);
    return [];
  }
};

// Post vendor (create new vendor) ---------------------------------------------------------------
export const postVendor = async (vendor) => {
  const usertoken = localStorage.getItem('token'); // Make sure you are handling the token correctly
  try {
    const response = await fetch(`${API_BASE_URL}/vendor/post-vendor`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": 'Bearer ' + usertoken,
      },
      body: JSON.stringify({
        name: vendor.name,
        website: vendor.website,
      }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error saving vendor:', error);
    throw error;
  }
};


