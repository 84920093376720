// import React, { useState } from "react";
// import "./DesignPopup.css";

// const AddDesign = ({ design, onClose }) => {
//   const isEditMode = design && design.designType !== undefined;

//   // State for managing available and selected components
//   const [availableComponents, setAvailableComponents] = useState(design || []);
//   const [selectedComponents, setSelectedComponents] = useState([]);
//   const [selectedAvailableComponent, setSelectedAvailableComponent] = useState(null);
//   const [selectedSelectedComponent, setSelectedSelectedComponent] = useState(null);

//   // Handle selecting an available component
//   const handleSelectAvailableComponent = (index) => {
//     setSelectedAvailableComponent(index);
//     setSelectedSelectedComponent(null); // Deselect from the other list
//   };

//   // Handle selecting a selected component
//   const handleSelectSelectedComponent = (index) => {
//     setSelectedSelectedComponent(index);
//     setSelectedAvailableComponent(null); // Deselect from the other list
//   };

//   // Move component from available to selected
//   const handleMoveToSelected = () => {
//     if (selectedAvailableComponent !== null) {
//       const componentToMove = availableComponents[selectedAvailableComponent];
//       setAvailableComponents(availableComponents.filter((_, i) => i !== selectedAvailableComponent));
//       setSelectedComponents([...selectedComponents, componentToMove]);
//       setSelectedAvailableComponent(null); // Clear the selection
//     }
//   };

//   // Move component from selected to available
//   const handleMoveToAvailable = () => {
//     if (selectedSelectedComponent !== null) {
//       const componentToMove = selectedComponents[selectedSelectedComponent];
//       setSelectedComponents(selectedComponents.filter((_, i) => i !== selectedSelectedComponent));
//       setAvailableComponents([...availableComponents, componentToMove]);
//       setSelectedSelectedComponent(null); // Clear the selection
//     }
//   };

//   return (
//     <div className="popup-overlay">
//       <div className="popup-content">
//         <div className="popup-header">
//           <h2 className="text-center">
//             {isEditMode
//               ? "Please modify the information of the selected design type"
//               : "Please add a new design type"}
//           </h2>
//           <button className="close-button" onClick={onClose}>
//             &times;
//           </button>
//         </div>
//         <div className="popup-body">
//           <label htmlFor="designType">Design Type:</label>
//           <input
//             type="text"
//             id="designType"
//             value={design ? design.designType : ""}
//             className="design-type-input"
//             placeholder={!isEditMode ? "Enter design name" : ""}
//             readOnly
//           />

//           <div className="components-section">
//             <div className="components-list-container">
//               <h3>Available component types</h3>
//               <ul className="components-list">
//                 {availableComponents.length > 0 ? (
//                   availableComponents.map((component, index) => (
//                     <li
//                       key={index}
//                       onClick={() => handleSelectAvailableComponent(index)}
//                       className={selectedAvailableComponent === index ? "selected" : ""}
//                     >
//                       {component.componentsDescription}
//                     </li>
//                   ))
//                 ) : (
//                   <li>No components available</li>
//                 )}
//               </ul>
//             </div>
//             <div className="buttons-section">
//               <button
//                 className="transfer-button"
//                 onClick={handleMoveToSelected}
//                 disabled={selectedAvailableComponent === null}
//               >
//                 &gt;
//               </button>
//               <button
//                 className="transfer-button"
//                 onClick={handleMoveToAvailable}
//                 disabled={selectedSelectedComponent === null}
//               >
//                 &lt;
//               </button>
//             </div>
//             <div className="components-list-container">
//               <h3>Selected component types</h3>
//               <ul className="components-list">
//                 {selectedComponents.length > 0 ? (
//                   selectedComponents.map((component, index) => (
//                     <li
//                       key={index}
//                       onClick={() => handleSelectSelectedComponent(index)}
//                       className={selectedSelectedComponent === index ? "selected" : ""}
//                     >
//                       {component.componentsDescription}
//                     </li>
//                   ))
//                 ) : (
//                   <li>No components selected</li>
//                 )}
//               </ul>
//             </div>
//           </div>
//         </div>
//         <div className="popup-actions">
//           <button className="save-button">
//             {isEditMode ? "Save" : "Save"}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AddDesign;



// import React, { useState } from "react";
// import { saveDesign } from './apiService'; 
// import "./DesignPopup.css";

// const AddDesign = ({ design, onClose , selectedComponentIds}) => {
//   const isEditMode = design && design.designType !== undefined;

//   const [availableComponents, setAvailableComponents] = useState(design || []);
//   const [selectedComponents, setSelectedComponents] = useState([]);
//   const [selectedAvailableComponent, setSelectedAvailableComponent] = useState(null);
//   const [selectedSelectedComponent, setSelectedSelectedComponent] = useState(null);

//   console.log("ffffffffffffffffffffffffffffff",design)
//   console.log('idddddddddddddddddddddd',selectedComponentIds)
//   // Handle selecting an available component
//   const handleSelectAvailableComponent = (index) => {
//     setSelectedAvailableComponent(index);
//     setSelectedSelectedComponent(null); 
//   };

//   const [designType, setDesignType] = useState(design ? design.designType : "New Design");


//   // Handle selecting a selected component
//   const handleSelectSelectedComponent = (index) => {
//     setSelectedSelectedComponent(index);
//     setSelectedAvailableComponent(null); // Deselect from the other list
//   };

//   // Move component from available to selected
//   const handleMoveToSelected = () => {
//     if (selectedAvailableComponent !== null) {
//       const componentToMove = availableComponents[selectedAvailableComponent];
//       setAvailableComponents(availableComponents.filter((_, i) => i !== selectedAvailableComponent));
//       setSelectedComponents([...selectedComponents, componentToMove]);
//       setSelectedAvailableComponent(null); // Clear the selection
//     }
//   };

//   // Move component from selected to available
//   const handleMoveToAvailable = () => {
//     if (selectedSelectedComponent !== null) {
//       const componentToMove = selectedComponents[selectedSelectedComponent];
//       setSelectedComponents(selectedComponents.filter((_, i) => i !== selectedSelectedComponent));
//       setAvailableComponents([...availableComponents, componentToMove]);
//       setSelectedSelectedComponent(null); // Clear the selection
//     }
//   };

//   // Handle save button click
//   const handleSave = async () => {
//     const designData = {
//         designType: design ? design.designType : "New Design",
//         designComponentIds: selectedComponents.map((component) => component.id), // Map selected components to IDs
//     };

//     try {
//       const response = await saveDesign(designData);
//       if (response) {
//         alert("Design saved successfully!");
//         onClose(); // Close the popup after saving
//       } else {
//         alert("Failed to save design.");
//       }
//     } catch (error) {
//       console.error("Error saving design:", error);
//       alert("An error occurred while saving.");
//     }
//   };

// // Function to handle when a description is selected
// const handleComponentSelection = (selectedDescription) => {
//     console.log("Selected Description:", selectedDescription); // Log selected description
  
//     // Check if availableComponents is defined and contains data
//     console.log("Available Components: ", availableComponents); 
    
//     // Find the component that matches the selected description
//     const selectedComponent = availableComponents.find(
//       (component) => component.componentsDescription === selectedDescription
//     );
  
//     if (selectedComponent) {
//       // Log the corresponding componentId
//       console.log("Selected Component ID:", selectedComponent.id);
//     } else {
//       console.log("Component not found.");
//     }
//   };
  
//   // Example of when you might call this function
//   const onSelectDescription = (event) => {
//     console.log("Event Triggered: ", event);  // Log the event to see if it's firing correctly
//     const selectedDescription = event.target.innerText;  // Assuming innerText contains the description
//     handleComponentSelection(selectedDescription);
//   };
  
  


//   return (
//     <div className="popup-overlay">
//       <div className="popup-content">
//         <div className="popup-header">
//           <h2 className="text-center">
//             {isEditMode
//               ? "Please modify the information of the selected design type"
//               : "Please add a new design type"}
//           </h2>
//           <button className="close-button" onClick={onClose}>
//             &times;
//           </button>
//         </div>
//         <div className="popup-body">
//           <label htmlFor="designType">Design Type:</label>
//           <input
//             type="text"
//             id="designType"
//             value={design ? design.designType : ""}
//             className="design-type-input"
//             placeholder={!isEditMode ? "Enter design name" : ""}
           
//           />

//           <div className="components-section">
//             <div className="components-list-container">
//               <h3>Available component types</h3>
//               <ul className="components-list">
//                 {availableComponents.length > 0 ? (
//                   availableComponents.map((component, index) => (
//                     <li
//                       key={index}
//                       onClick={() => handleSelectAvailableComponent(index)}
//                       className={selectedAvailableComponent === index ? "selected" : ""}
//                     >
//                       {component.componentsDescription}
//                     </li>
//                   ))
//                 ) : (
//                   <li>No components available</li>
//                 )}
//               </ul>
//             </div>
//             <div className="buttons-section">
//               <button
//                 className="transfer-button"
//                 onClick={handleMoveToSelected}
//                 disabled={selectedAvailableComponent === null}
//               >
//                 &gt;
//               </button>
//               <button
//                 className="transfer-button"
//                 onClick={handleMoveToAvailable}
//                 disabled={selectedSelectedComponent === null}
//               >
//                 &lt;
//               </button>
//             </div>
//             <div className="components-list-container">
//               <h3>Selected component types</h3>
//               <ul className="components-list">
//                 {selectedComponents.length > 0 ? (
//                   selectedComponents.map((component, index) => (
//                     <li
//                       key={index}
//                       onClick={() => handleSelectSelectedComponent(index)}
//                       className={selectedSelectedComponent === index ? "selected" : ""}
//                     >
//                       {component.componentsDescription}
//                     </li>
//                   ))
//                 ) : (
//                   <li>No components selected</li>
//                 )}
//               </ul>
//             </div>
//           </div>
//         </div>
//         <div className="popup-actions">
//           <button className="save-button" onClick={handleSave}>
//             {isEditMode ? "Save" : "Save"}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AddDesign;

// import React, { useState, useEffect } from "react";
// import "./DesignPopup.css";
// import { saveDesign } from './apiService';

// const AddDesign = ({ design, onClose, selectedComponentIds = [] }) => {
//   const isEditMode = design && design.designType !== undefined;
//   const [availableComponents, setAvailableComponents] = useState(design || []);
//   const [selectedComponents, setSelectedComponents] = useState([]);
//   const [selectedAvailableComponent, setSelectedAvailableComponent] = useState(null);
//   const [selectedSelectedComponent, setSelectedSelectedComponent] = useState(null);
//   const [designType, setDesignType] = useState(design ? design.designType : "New Design");

//   useEffect(() => {
//     if (selectedComponentIds.length > 0) {
//       const initialSelectedComponents = availableComponents.filter(component =>
//         selectedComponentIds.includes(component.id)
//       );
//       setSelectedComponents(initialSelectedComponents);
//     }
//   }, [selectedComponentIds, availableComponents]);

//   const handleSelectAvailableComponent = (index) => {
//     setSelectedAvailableComponent(index);
//     setSelectedSelectedComponent(null);
//   };

//   const handleSelectSelectedComponent = (index) => {
//     setSelectedSelectedComponent(index);
//     setSelectedAvailableComponent(null);
//   };

//   const handleMoveToSelected = () => {
//     if (selectedAvailableComponent !== null) {
//       const componentToMove = availableComponents[selectedAvailableComponent];
//       setAvailableComponents(availableComponents.filter((_, i) => i !== selectedAvailableComponent));
//       setSelectedComponents([...selectedComponents, componentToMove]);
//       setSelectedAvailableComponent(null);
//     }
//   };

//   const handleMoveToAvailable = () => {
//     if (selectedSelectedComponent !== null) {
//       const componentToMove = selectedComponents[selectedSelectedComponent];
//       setSelectedComponents(selectedComponents.filter((_, i) => i !== selectedSelectedComponent));
//       setAvailableComponents([...availableComponents, componentToMove]);
//       setSelectedSelectedComponent(null);
//     }
//   };

//   const handleSave = async () => {
//     const designData = {
//       designType: design ? design.designType : "New Design",
//       designComponentIds: selectedComponents.map((component) => component.id),
//     };
//     console.log("Design Data to Save:", designData);

//     try {
//       const response = await saveDesign(designData);
//       if (response) {
//         alert("Design saved successfully!");
//         onClose();
//       } else {
//         alert("Failed to save design.");
//       }
//     } catch (error) {
//       console.error("Error saving design:", error);
//       alert("An error occurred while saving.");
//     }
//   };

//   return (
//     <div className="popup-overlay">
//       <div className="popup-content">
//         <div className="popup-header">
//           <h2 className="text-center">
//             {isEditMode ? "Please modify the information of the selected design type" : "Please add a new design type"}
//           </h2>
//           <button className="close-button" onClick={onClose}>
//             &times;
//           </button>
//         </div>
//         <div className="popup-body">
//           <label htmlFor="designType">Design Type:</label>
//           <input
//             type="text"
//             id="designType"
//             value={design ? design.designType : ""}
//             className="design-type-input"
//             placeholder={!isEditMode ? "Enter design name" : ""}
//             onChange={(e) => setDesignType(e.target.value)}
//           />
//           <div className="components-section">
//             <div className="components-list-container">
//               <h3>Available component types</h3>
//               <ul className="components-list">
//                 {availableComponents.length > 0 ? (
//                   availableComponents.map((component, index) => (
//                     <li
//                       key={index}
//                       onClick={() => handleSelectAvailableComponent(index)}
//                       className={selectedAvailableComponent === index ? "selected" : ""}
//                     >
//                       {component.componentsDescription}
//                     </li>
//                   ))
//                 ) : (
//                   <li>No components available</li>
//                 )}
//               </ul>
//             </div>
//             <div className="buttons-section">
//               <button
//                 className="transfer-button"
//                 onClick={handleMoveToSelected}
//                 disabled={selectedAvailableComponent === null}
//               >
//                 &gt;
//               </button>
//               <button
//                 className="transfer-button"
//                 onClick={handleMoveToAvailable}
//                 disabled={selectedSelectedComponent === null}
//               >
//                 &lt;
//               </button>
//             </div>
//             <div className="components-list-container">
//               <h3>Selected component types</h3>
//               <ul className="components-list">
//                 {selectedComponents.length > 0 ? (
//                   selectedComponents.map((component, index) => (
//                     <li
//                       key={index}
//                       onClick={() => handleSelectSelectedComponent(index)}
//                       className={selectedSelectedComponent === index ? "selected" : ""}
//                     >
//                       {component.componentsDescription}
//                     </li>
//                   ))
//                 ) : (
//                   <li>No components selected</li>
//                 )}
//               </ul>
//             </div>
//           </div>
//         </div>
//         <div className="popup-actions">
//           <button className="save-button" onClick={handleSave}>
//             {isEditMode ? "Save" : "Save"}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AddDesign;






// import React, { useState, useEffect } from "react";
// import "./DesignPopup.css";
// import { saveDesign } from './apiService';

// const AddDesign = ({ design, onClose, selectedComponentIds = [] }) => {
//   const isEditMode = design && design.designType !== undefined;
//   const [availableComponents, setAvailableComponents] = useState(design || []);
//   const [selectedComponents, setSelectedComponents] = useState([]);
//   const [designType, setDesignType] = useState(design ? design.designType : "New Design");
//   const [selectedAvailableComponentIndex, setSelectedAvailableComponentIndex] = useState(null);
//   const [selectedSelectedComponentIndex, setSelectedSelectedComponentIndex] = useState(null);

//   useEffect(() => {
//     if (selectedComponentIds.length > 0) {
//       const initialSelectedComponents = availableComponents.filter(component =>
//         selectedComponentIds.includes(component.id)
//       );
//       setSelectedComponents(initialSelectedComponents);
//       setAvailableComponents(
//         availableComponents.filter(component => !selectedComponentIds.includes(component.id))
//       );
//     }
//   }, [selectedComponentIds, availableComponents]);

//   // Select an available component by index
//   const handleSelectAvailableComponent = (index) => {
//     setSelectedAvailableComponentIndex(index);
//     setSelectedSelectedComponentIndex(null); // Clear selected from the other list
//   };

//   // Select a selected component by index
//   const handleSelectSelectedComponent = (index) => {
//     setSelectedSelectedComponentIndex(index);
//     setSelectedAvailableComponentIndex(null); // Clear selected from the other list
//   };

//   // Move a component from available to selected
//   const handleMoveToSelected = () => {
//     if (selectedAvailableComponentIndex !== null) {
//       const componentToMove = availableComponents[selectedAvailableComponentIndex];
//       setAvailableComponents(availableComponents.filter((_, i) => i !== selectedAvailableComponentIndex));
//       setSelectedComponents([...selectedComponents, componentToMove]);
//       setSelectedAvailableComponentIndex(null);
//     }
//   };

//   // Move a component from selected back to available
//   const handleMoveToAvailable = () => {
//     if (selectedSelectedComponentIndex !== null) {
//       const componentToMove = selectedComponents[selectedSelectedComponentIndex];
//       setSelectedComponents(selectedComponents.filter((_, i) => i !== selectedSelectedComponentIndex));
//       setAvailableComponents([...availableComponents, componentToMove]);
//       setSelectedSelectedComponentIndex(null);
//     }
//   };

//   // Save the design data
//   const handleSave = async () => {
//     const designData = {
//       designType: designType || "New Design",
//       designComponentIds: selectedComponents.map((component) => component.id),
//     };
//     console.log("Design Data to Save:", designData);
  
//     try {
//       const response = await saveDesign(designData);
//       if (response) {
//         alert("Design saved successfully!");
//         onClose();
//       } else {
//         alert("Failed to save design.");
//       }
//     } catch (error) {
//       console.error("Error saving design:", error);
//       alert("An error occurred while saving.");
//     }
//   };

//   return (
//     <div className="popup-overlay">
//       <div className="popup-content">
//         <div className="popup-header">
//           <h2 className="text-center">
//             {isEditMode ? "Please modify the information of the selected design type" : "Please add a new design type"}
//           </h2>
//           <button className="close-button" onClick={onClose}>
//             &times;
//           </button>
//         </div>
//         <div className="popup-body">
//           <label htmlFor="designType">Design Type:</label>
//           <input
//             type="text"
//             id="designType"
//             value={designType}
//             className="design-type-input"
//             placeholder={!isEditMode ? "Enter design name" : ""}
//             onChange={(e) => setDesignType(e.target.value)}
//           />
//           <div className="components-section">
//             <div className="components-list-container">
//               <h3>Available component types</h3>
//               <ul className="components-list">
//                 {availableComponents.length > 0 ? (
//                   availableComponents.map((component, index) => (
//                     <li
//                       key={component.id}
//                       onClick={() => handleSelectAvailableComponent(index)}
//                       className={selectedAvailableComponentIndex === index ? "selected" : ""}
//                     >
//                       {component.componentsDescription}
//                     </li>
//                   ))
//                 ) : (
//                   <li>No components available</li>
//                 )}
//               </ul>
//             </div>
//             <div className="buttons-section">
//               <button
//                 className="transfer-button"
//                 onClick={handleMoveToSelected}
//                 disabled={selectedAvailableComponentIndex === null} 
//               >
//                 &gt;
//               </button>
//               <button
//                 className="transfer-button"
//                 onClick={handleMoveToAvailable}
//                 disabled={selectedSelectedComponentIndex === null} 
//               >
//                 &lt;
//               </button>
//             </div>
//             <div className="components-list-container">
//               <h3>Selected component types</h3>
//               <ul className="components-list">
//                 {selectedComponents.length > 0 ? (
//                   selectedComponents.map((component, index) => (
//                     <li
//                       key={component.id}
//                       onClick={() => handleSelectSelectedComponent(index)}
//                       className={selectedSelectedComponentIndex === index ? "selected" : ""}
//                     >
//                       {component.componentsDescription}
//                     </li>
//                   ))
//                 ) : (
//                   <li>No components selected</li>
//                 )}
//               </ul>
//             </div>
//           </div>
//         </div>
//         <div className="popup-actions">
//           <button className="save-button" onClick={handleSave}>
//             {isEditMode ? "Save" : "Save"}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AddDesign;





import React, { useState, useEffect } from "react";
import "./DesignPopup.css";
import { saveDesign } from './apiService';

const AddDesign = ({ design, onClose, selectedComponentIds = [] }) => {
  const isEditMode = design && design.designType !== undefined;
  const [availableComponents, setAvailableComponents] = useState(design || []);
  const [selectedComponents, setSelectedComponents] = useState([]);
  const [designType, setDesignType] = useState(design ? design.designType : "New Design");
  const [selectedAvailableComponentIndex, setSelectedAvailableComponentIndex] = useState(null);
  const [selectedSelectedComponentIndex, setSelectedSelectedComponentIndex] = useState(null);
  const [designComponentId, setDesignComponentId] = useState(null); // To hold the selected component ID

  useEffect(() => {
    if (selectedComponentIds.length > 0) {
      const initialSelectedComponents = availableComponents.filter(component =>
        selectedComponentIds.includes(component.id)
      );
      setSelectedComponents(initialSelectedComponents);
      setAvailableComponents(
        availableComponents.filter(component => !selectedComponentIds.includes(component.id))
      );
    }
  }, [selectedComponentIds, availableComponents]);


  console.log("selectedddddddd",selectedComponentIds)
  console.log("availav",availableComponents)

  // Select an available component by index
  const handleSelectAvailableComponent = (index) => {
    setSelectedAvailableComponentIndex(index);
    setSelectedSelectedComponentIndex(null); // Clear selected from the other list
    setDesignComponentId(availableComponents[index].id); // Set the component ID
  };

  // Select a selected component by index
  const handleSelectSelectedComponent = (index) => {
    setSelectedSelectedComponentIndex(index);
    setSelectedAvailableComponentIndex(null); // Clear selected from the other list
    setDesignComponentId(selectedComponents[index].id); // Set the component ID
  };

  // Move a component from available to selected
  const handleMoveToSelected = () => {
    if (selectedAvailableComponentIndex !== null) {
      const componentToMove = availableComponents[selectedAvailableComponentIndex];
      setAvailableComponents(availableComponents.filter((_, i) => i !== selectedAvailableComponentIndex));
      setSelectedComponents([...selectedComponents, componentToMove]);
      setSelectedAvailableComponentIndex(null);
      setDesignComponentId(null); // Reset component ID after move
    }
  };

  // Move a component from selected back to available
  const handleMoveToAvailable = () => {
    if (selectedSelectedComponentIndex !== null) {
      const componentToMove = selectedComponents[selectedSelectedComponentIndex];
      setSelectedComponents(selectedComponents.filter((_, i) => i !== selectedSelectedComponentIndex));
      setAvailableComponents([...availableComponents, componentToMove]);
      setSelectedSelectedComponentIndex(null);
      setDesignComponentId(null); // Reset component ID after move
    }
  };

  const handleSave = async () => {
    const designData = {
      designType: designType || "New Design",
      designComponentIds: selectedComponents.map((component) => component.id), // Get component IDs
    };
  
    console.log("Design Data to Save:", designData);
    // Log individual designComponentIds for detailed inspection
    designData.designComponentIds.forEach((id, index) => {
      console.log(`Component ID ${index + 1}:`, id);
    });
  
    try {
      const response = await saveDesign(designData);
      if (response) {
        alert("Design saved successfully!");
        onClose();
      } else {
        alert("Failed to save design.");
      }
    } catch (error) {
      console.error("Error saving design:", error);
      alert("An error occurred while saving.");
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-header">
          <h2 className="text-center">
            {isEditMode ? "Please modify the information of the selected design type" : "Please add a new design type"}
          </h2>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="popup-body">
          <label htmlFor="designType">Design Type:</label>
          <input
            type="text"
            id="designType"
            value={designType}
            className="design-type-input"
            placeholder={!isEditMode ? "Enter design name" : ""}
            onChange={(e) => setDesignType(e.target.value)}
          />
          <div className="components-section">
            <div className="components-list-container">
              <h3>Available component types</h3>
              <ul className="components-list">
                {availableComponents.length > 0 ? (
                  availableComponents.map((component, index) => (
                    <li
                      key={component.id}
                      onClick={() => handleSelectAvailableComponent(index)}
                      className={selectedAvailableComponentIndex === index ? "selected" : ""}
                    >
                      {component.componentsDescription}
                    </li>
                  ))
                ) : (
                  <li>No components available</li>
                )}
              </ul>
            </div>
            <div className="buttons-section">
              <button
                className="transfer-button"
                onClick={handleMoveToSelected}
                disabled={selectedAvailableComponentIndex === null} 
              >
                &gt;
              </button>
              <button
                className="transfer-button"
                onClick={handleMoveToAvailable}
                disabled={selectedSelectedComponentIndex === null} 
              >
                &lt;
              </button>
            </div>
            <div className="components-list-container">
              <h3>Selected component types</h3>
              <ul className="components-list">
                {selectedComponents.length > 0 ? (
                  selectedComponents.map((component, index) => (
                    <li
                      key={component.id}
                      onClick={() => handleSelectSelectedComponent(index)}
                      className={selectedSelectedComponentIndex === index ? "selected" : ""}
                    >
                      {component.componentsDescription}
                    </li>
                  ))
                ) : (
                  <li>No components selected</li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="popup-actions">
          <button className="save-button" onClick={handleSave}>
            {isEditMode ? "Save" : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddDesign;
