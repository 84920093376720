// import React, { useState ,useEffect} from "react";
// import "./DesignPopup.css";

// const DesignPopup = ({ design, onClose, components }) => {
//   const isEditMode = design && design.name !== undefined;

//   const [availableComponents, setAvailableComponents] = useState(components || []);
//   const [selectedComponents, setSelectedComponents] = useState([]);
//   const [designType, setDesignType] = useState(design ? design.name : "");

//   const [selectedComponent, setSelectedComponent] = useState(null);


//   useEffect(() => {
//     if (design) {
//       setDesignType(design.name);
//     }
//   }, [design]);

//   console.log("dtypeeeeeee",design.designType)
//   const moveToSelected = () => {
//     if (selectedComponent) {
//       setSelectedComponents([...selectedComponents, selectedComponent]);
//       setAvailableComponents(availableComponents.filter(component => component.id !== selectedComponent.id));
//       setSelectedComponent(null);
//     }
//   };

//   const moveToAvailable = () => {
//     if (selectedComponent) {
//       setAvailableComponents([...availableComponents, selectedComponent]);
//       setSelectedComponents(selectedComponents.filter(component => component.id !== selectedComponent.id));
//       setSelectedComponent(null); 
//     }
//   };

  
//   const handleComponentClick = (component) => {
//     setSelectedComponent(component);
//   };

//   return (
//     <div className="popup-overlay">
//       <div className="popup-content">
//         <div className="popup-header">
//           <h2 className="text-center">
//             {isEditMode ? "Please modify the information of the selected design type" : "Please add a new design type"}
//           </h2>
//           <button className="close-button" onClick={onClose}>
//             &times;
//           </button>
//         </div>
//         <div className="popup-body">
//           <label htmlFor="designType">Design Type:</label>
//           <input
//             type="text"
//             id="designType"
//             value={design.designType}
//             readOnly={isEditMode}
//             className="design-type-input"
//             placeholder={!isEditMode ? "Enter design name" : ""}
//           />
//           <div className="components-section">
//             <div className="components-list-container">
//               <h3>Available component types</h3>
//               <ul className="components-list">
//                 {availableComponents.length > 0 ? (
//                   availableComponents.map((component) => (
//                     <li
//                       key={component.id}
//                       className={selectedComponent && selectedComponent.id === component.id ? 'selected' : ''}
//                       onClick={() => handleComponentClick(component)}
//                     >
//                       {component.description}
//                     </li>
//                   ))
//                 ) : (
//                   <li> no component available </li>
//                 )}
//               </ul>
//             </div>
//             <div className="buttons-section">
//               <button className="transfer-button" onClick={moveToSelected}> &gt; </button>
//               <button className="transfer-button" onClick={moveToAvailable}> &lt; </button>
//             </div>
//             <div className="components-list-container">
//               <h3>Selected component types</h3>
//               <ul className="components-list">
//                 {selectedComponents.length > 0 ? (
//                   selectedComponents.map((component) => (
//                     <li
//                       key={component.id}
//                       className={selectedComponent && selectedComponent.id === component.id ? 'selected' : ''}
//                       onClick={() => handleComponentClick(component)}
//                     >
//                       {component.description}
//                     </li>
//                   ))
//                 ) : (
//                   <li>No components selected</li>
//                 )}
//               </ul>
//             </div>
//           </div>
//         </div>
//         <div className="popup-actions">
//           <button className="save-button">{isEditMode ? "Save" : "save"}</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default DesignPopup;





// import React, { useState, useEffect } from "react";
// import "./DesignPopup.css";
// import {postSolarDesign} from '../../pages/Admin/apiService.jsx'; 

// const DesignPopup = ({   design, onClose, components }) => {
//   const isEditMode = design && design.name !== undefined;

//   const [availableComponents, setAvailableComponents] = useState(components || []);
//   const [selectedComponents, setSelectedComponents] = useState([]);
//   const [designType, setDesignType] = useState(design ? design.name : "");

//   const [selectedComponent, setSelectedComponent] = useState(null);
  
//   useEffect(() => {
//     if (design) {
//       setDesignType(design.designType);
//       console.log("Design Type:", design.designType); 
//     }
//   }, [design]);
//   const moveToSelected = () => {
//     if (selectedComponent) {
//       setSelectedComponents([...selectedComponents, selectedComponent]);
//       setAvailableComponents(availableComponents.filter(component => component.id !== selectedComponent.id));
//       setSelectedComponent(null);
//     }
//   };

//   const moveToAvailable = () => {
//     if (selectedComponent) {
//       setAvailableComponents([...availableComponents, selectedComponent]);
//       setSelectedComponents(selectedComponents.filter(component => component.id !== selectedComponent.id));
//       setSelectedComponent(null);
//     }
//   };

//   const handleComponentClick = (component) => {
//     setSelectedComponent(component);
//   };

//   const saveDesign = async () => {
//     const designData = {
//       designId: design ? design.designId : 0,
//       designType: designType,  
//       description: selectedComponents.map(component => component.description).join(', '),
//       designComponentIds: selectedComponents.map(component => component.id)  
//     };
  
//     try {
//       console.log("Saving design with data:", designData);
//       const response = await postSolarDesign(designData);
//       if (response.message !== 'Success.') {
//         throw new Error(`Error: ${response.message}`);
//       }
//       console.log("Design saved successfully:", response);
//       onClose();
//     } catch (error) {
//       console.error("Error saving design:", error);
//     }
//   };
//   return (
//     <div className="popup-overlay">
//       <div className="popup-content">
//         <div className="popup-header">
//           <h2 className="text-center">
//             {isEditMode ? "Please modify the information of the selected design type" : "Please add a new design type"}
//           </h2>
//           <button className="close-button" onClick={onClose}>
//             &times;
//           </button>
//         </div>
//         <div className="popup-body">
//           <label htmlFor="designType">Design Type:</label>
//           <input
//             type="text"
//             id="designType"
//             value={designType} 
//             readOnly={isEditMode}
//             className="design-type-input"
//             placeholder={!isEditMode ? "Enter design name" : ""}
//           />
//           <div className="components-section">
//             <div className="components-list-container">
//               <h3>Available component types</h3>
//               <ul className="components-list">
//                 {availableComponents.length > 0 ? (
//                   availableComponents.map((component) => (
//                     <li
//                       key={component.id}
//                       className={selectedComponent && selectedComponent.id === component.id ? 'selected' : ''}
//                       onClick={() => handleComponentClick(component)}
//                     >
//                       {component.description}
//                     </li>
//                   ))
//                 ) : (
//                   <li>no component available</li>
//                 )}
//               </ul>
//             </div>
//             <div className="buttons-section">
//               <button className="transfer-button" onClick={moveToSelected}> &gt; </button>
//               <button className="transfer-button" onClick={moveToAvailable}> &lt; </button>
//             </div>
//             <div className="components-list-container">
//               <h3>Selected component types</h3>
//               <ul className="components-list">
//                 {selectedComponents.length > 0 ? (
//                   selectedComponents.map((component) => (
//                     <li
//                       key={component.id}
//                       className={selectedComponent && selectedComponent.id === component.id ? 'selected' : ''}
//                       onClick={() => handleComponentClick(component)}
//                     >
//                       {component.description}
//                     </li>
//                   ))
//                 ) : (
//                   <li>No components selected</li>
//                 )}
//               </ul>
//             </div>
//           </div>
//         </div>
//         <div className="popup-actions">
//           <button className="save-button" onClick={saveDesign}>
//             {isEditMode ? "Save" : "Save"}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default DesignPopup;



// import React, { useState, useEffect } from "react";
// import "./DesignPopup.css";
// import { putSolarDesign } from '../../pages/Admin/apiService.jsx'; 

// const DesignPopup = ({ design, onClose, components }) => {
//   const isEditMode = design && design.name !== undefined;

//   const [availableComponents, setAvailableComponents] = useState(components || []);
//   const [selectedComponents, setSelectedComponents] = useState([]);
//   const [designType, setDesignType] = useState(design ? design.name : "");
//   const [selectedComponent, setSelectedComponent] = useState(null);
  
//   useEffect(() => {
//     if (design) {
//       setDesignType(design.designType);
//     }
//   }, [design]);

//   const moveToSelected = () => {
//     if (selectedComponent) {
//       setSelectedComponents([...selectedComponents, selectedComponent]);
//       setAvailableComponents(availableComponents.filter(component => component.id !== selectedComponent.id));
//       setSelectedComponent(null);
//     }
//   };

//   const moveToAvailable = () => {
//     if (selectedComponent) {
//       setAvailableComponents([...availableComponents, selectedComponent]);
//       setSelectedComponents(selectedComponents.filter(component => component.id !== selectedComponent.id));
//       setSelectedComponent(null);
//     }
//   };

//   const handleComponentClick = (component) => {
//     setSelectedComponent(component);
//   };

//   const saveDesign = async () => {
//     const designData = {
//       designId: design ? design.designId : 0,
//       designType: designType,  
//       description: selectedComponents.map(component => component.description).join(', '),
//       designComponentIds: selectedComponents.map(component => component.id)  
//     };
  
//     try {
//       console.log("Saving design with data:", designData);
//       const response = await putSolarDesign(designData);  
//       if (response.message !== 'Success.') {
//         throw new Error(`Error: ${response.message}`);
//       }
//       console.log("Design saved successfully:", response);
//       onClose();
//     } catch (error) {
//       console.error("Error saving design:", error);
//     }
//   };

//   return (
//     <div className="popup-overlay">
//       <div className="popup-content">
//         <div className="popup-header">
//           <h2 className="text-center">
//             {isEditMode ? "Please modify the information of the selected design type" : "Please add a new design type"}
//           </h2>
//           <button className="close-button" onClick={onClose}>
//             &times;
//           </button>
//         </div>
//         <div className="popup-body">
//           <label htmlFor="designType">Design Type:</label>
//           <input
//   type="text"
//   id="designType"
//   value={designType}
//   onChange={(e) => setDesignType(e.target.value)} 
//   className="design-type-input"
//   placeholder={!isEditMode ? "Enter design name" : ""}
// />

//           <div className="components-section">
//             <div className="components-list-container">
//               <h3>Available component types</h3>
//               <ul className="components-list">
//                 {availableComponents.length > 0 ? (
//                   availableComponents.map((component) => (
//                     <li
//                       key={component.id}
//                       className={selectedComponent && selectedComponent.id === component.id ? 'selected' : ''}
//                       onClick={() => handleComponentClick(component)}
//                     >
//                       {component.componentsDescription}
//                       {component.description}
//                     </li>
//                   ))
//                 ) : (
//                   <li>no component available</li>
//                 )}
//               </ul>
//             </div>
//             <div className="buttons-section">
//               <button className="transfer-button" onClick={moveToSelected}> &gt; </button>
//               <button className="transfer-button" onClick={moveToAvailable}> &lt; </button>
//             </div>
//             <div className="components-list-container">
//               <h3>Selected component types</h3>
//               <ul className="components-list">
//                 {selectedComponents.length > 0 ? (
//                   selectedComponents.map((component) => (
//                     <li
//                       key={component.id}
//                       className={selectedComponent && selectedComponent.id === component.id ? 'selected' : ''}
//                       onClick={() => handleComponentClick(component)}
//                     >
//                        {component.componentsDescription}
//                       {component.description}
//                     </li>
//                   ))
//                 ) : (
//                   <li>No components selected</li>
//                 )}
//               </ul>
//             </div>
//           </div>
//         </div>
//         <div className="popup-actions">
//           <button className="save-button" onClick={saveDesign}>
//             {isEditMode ? "Save" : "Save"}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default DesignPopup;



// import React, { useState, useEffect } from "react";
// import "./DesignPopup.css";
// import { putSolarDesign } from '../../pages/Admin/apiService.jsx'; 

// const DesignPopup = ({ design, onClose, components }) => {
//   const isEditMode = design && design.name !== undefined;

//   const [availableComponents, setAvailableComponents] = useState(components || []);
//   const [selectedComponents, setSelectedComponents] = useState([]);
//   const [designType, setDesignType] = useState(design ? design.name : "");
//   const [selectedComponentId, setSelectedComponentId] = useState(null);
  
//   useEffect(() => {
//     if (design) {
//       setDesignType(design.designType);
//       setAvailableComponents(components || []);
//     }
//   }, [design, components]);

//   console.log("designnnnnnnnnnnnnnnnn",selectedComponentId)


//   const moveToSelected = () => {
//     if (selectedComponentId !== null) {
//       const componentToMove = availableComponents.find(comp => comp.id, design.id === selectedComponentId);
//       if (componentToMove) {
//         setSelectedComponents(prev => [...prev, componentToMove]);
//         setAvailableComponents(prev => prev.filter(component => component.id !== selectedComponentId));
//         setSelectedComponentId(null);
//       }
//     }
//   };

//   const moveToAvailable = () => {
//     if (selectedComponentId !== null) {
//       const componentToMove = selectedComponents.find(comp => comp.id === selectedComponentId);
//       if (componentToMove) {
//         setAvailableComponents(prev => [...prev, componentToMove]);
//         setSelectedComponents(prev => prev.filter(component => component.id !== selectedComponentId));
//         setSelectedComponentId(null);
//       }
//     }
//   };

//   // const handleComponentClick = (id) => {
//   //   setSelectedComponentId(id);
//   // };
//   const handleComponentClick = (id) => {
//     setSelectedComponentId(prevId => (prevId === id ? null : id));
    
//   };

//   const saveDesign = async () => {
//     const designData = {
//       designId: design ? design.designId : 0,
//       designType: designType,  
//       description: selectedComponents.map(component => component.description).join(', '),
//       designComponentIds: selectedComponents.map(component => component.id)  
//     };
  
//     try {
//       console.log("Saving design with data:", designData);
//       const response = await putSolarDesign(designData);  
//       if (response.message !== 'Success.') {
//         throw new Error(`Error: ${response.message}`);
//       }
//       console.log("Design saved successfully:", response);
//       onClose();
//     } catch (error) {
//       console.error("Error saving design:", error);
//     }
//   };

//   return (
//     <div className="popup-overlay">
//       <div className="popup-content">
//         <div className="popup-header">
//           <h2 className="text-center">
//             {isEditMode ? "Please modify the information of the selected design type" : "Please add a new design type"}
//           </h2>
//           <button className="close-button" onClick={onClose}>
//             &times;
//           </button>
//         </div>
//         <div className="popup-body">
//           <label htmlFor="designType">Design Type:</label>
//           <input
//             type="text"
//             id="designType"
//             value={designType}
//             onChange={(e) => setDesignType(e.target.value)} 
//             className="design-type-input"
//             placeholder={!isEditMode ? "Enter design name" : ""}
//           />

//           <div className="components-section">
//             <div className="components-list-container">
//               <h3>Available component types</h3>
//               <ul className="components-list">
//                 {availableComponents.length > 0 ? (
//                   availableComponents.map((component) => (
//                     <li
//                       key={component.id}
//                       className={selectedComponentId === component.id ? 'selected' : ''}
//                       onClick={() => handleComponentClick(component.id)}
//                     >
//                       {component.componentsDescription} - {component.description}
//                     </li>
//                   ))
//                 ) : (
//                   <li>No components available</li>
//                 )}
//               </ul>
//             </div>
//             <div className="buttons-section">
//               <button className="transfer-button" onClick={moveToSelected}> &gt; </button>
//               <button className="transfer-button" onClick={moveToAvailable}> &lt; </button>
//             </div>
//             <div className="components-list-container">
//               <h3>Selected component types</h3>
//               <ul className="components-list">
//                 {selectedComponents.length > 0 ? (
//                   selectedComponents.map((component) => (
//                     <li
//                       key={component.id}
//                       className={selectedComponentId === component.id ? 'selected' : ''}
//                       onClick={() => handleComponentClick(component.id)}
//                     >
//                       {component.componentsDescription} - {component.description}
//                     </li>
//                   ))
//                 ) : (
//                   <li>No components selected</li>
//                 )}
//               </ul>
//             </div>
//           </div>
//         </div>
//         <div className="popup-actions">
//           <button className="save-button" onClick={saveDesign}>
//             {isEditMode ? "Save" : "Save"}
//           </button>
//         </div>
//       </div>
      
//     </div>
//   );
// };

// export default DesignPopup;


import React, { useState, useEffect } from "react";
import "./DesignPopup.css";
import { putSolarDesign } from '../../pages/Admin/apiService.jsx'; 

const DesignPopup = ({ design, onClose, components }) => {
  const isEditMode = design && design.name !== undefined;

  const [availableComponents, setAvailableComponents] = useState(components || []);
  const [selectedComponents, setSelectedComponents] = useState([]);
  const [designType, setDesignType] = useState(design ? design.name : "");
  const [selectedComponentId, setSelectedComponentId] = useState(null);
  
  useEffect(() => {
    if (design) {
      setDesignType(design.designType);
      setAvailableComponents(components || []);
    }
  }, [design, components]);

  console.log("designnnnnnnnnnnnnnnnn", selectedComponentId);

  const moveToSelected = () => {
    if (selectedComponentId !== null) {
      const componentToMove = availableComponents.find(comp => comp.id === selectedComponentId);
      if (componentToMove) {
        setSelectedComponents(prev => [...prev, componentToMove]);
        setAvailableComponents(prev => prev.filter(component => component.id !== selectedComponentId));
        setSelectedComponentId(null);
      }
    }
  };

  const moveToAvailable = () => {
    if (selectedComponentId !== null) {
      const componentToMove = selectedComponents.find(comp => comp.id === selectedComponentId);
      if (componentToMove) {
        setAvailableComponents(prev => [...prev, componentToMove]);
        setSelectedComponents(prev => prev.filter(component => component.id !== selectedComponentId));
        setSelectedComponentId(null);
      }
    }
  };

  const handleComponentClick = (id) => {
    setSelectedComponentId(prevId => {
      const newId = (prevId === id ? null : id);
      console.log("Selected Component ID:", newId); // Log the selected component ID
      return newId;
    });
  };

  const saveDesign = async () => {
    const designData = {
      designId: design ? design.designId : 0,
      designType: designType,  
      description: selectedComponents.map(component => component.description).join(', '),
      designComponentIds: selectedComponents.map(component => component.id)  
    };
  
    try {
      console.log("Saving design with data:", designData);
      const response = await putSolarDesign(designData);  
      if (response.message !== 'Success.') {
        throw new Error(`Error: ${response.message}`);
      }
      console.log("Design saved successfully:", response);
      onClose();
    } catch (error) {
      console.error("Error saving design:", error);
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-header">
          <h2 className="text-center">
            {isEditMode ? "Please modify the information of the selected design type" : "Please add a new design type"}
          </h2>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="popup-body">
          <label htmlFor="designType">Design Type:</label>
          <input
            type="text"
            id="designType"
            value={designType}
            onChange={(e) => setDesignType(e.target.value)} 
            className="design-type-input"
            placeholder={!isEditMode ? "Enter design name" : ""}
          />

          <div className="components-section">
            <div className="components-list-container">
              <h3>Available component types</h3>
              <ul className="components-list">
                {availableComponents.length > 0 ? (
                  availableComponents.map((component) => (
                    <li
                      key={component.id}
                      className={selectedComponentId === component.id ? 'selected' : ''}
                      onClick={() => handleComponentClick(component.id)}
                    >
                      {component.componentsDescription} - {component.description}
                    </li>
                  ))
                ) : (
                  <li>No components available</li>
                )}
              </ul>
            </div>
            <div className="buttons-section">
              <button className="transfer-button" onClick={moveToSelected}> &gt; </button>
              <button className="transfer-button" onClick={moveToAvailable}> &lt; </button>
            </div>
            <div className="components-list-container">
              <h3>Selected component types</h3>
              <ul className="components-list">
                {selectedComponents.length > 0 ? (
                  selectedComponents.map((component) => (
                    <li
                      key={component.id}
                      className={selectedComponentId === component.id ? 'selected' : ''}
                      onClick={() => handleComponentClick(component.id)}
                    >
                      {component.componentsDescription} - {component.description}
                    </li>
                  ))
                ) : (
                  <li>No components selected</li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="popup-actions">
          <button className="save-button" onClick={saveDesign}>
            {isEditMode ? "Save" : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DesignPopup;
