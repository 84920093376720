import React, {useState} from 'react';
import './home.css';
import bannerImage from '../../assets/Solar-Panel-1.jpg';
import SolarModelImage from '../../assets/solar-model-2.jpg';
import Modal from 'react-bootstrap/Modal';
import Header from "../../component/common/header/Header";
import question from "../../assets/questions.jpg";
import allone from"../../assets/allinone.png";
import safety from"../../assets/safetys.png";
import speaker from"../../assets/speakers.png";
import thunder from "../../assets/thunders.png";
import watch from "../../assets/watchs.png";
import homepanel from "../../assets/homepanels.png";
import graph from "../../assets/graphs.png";
import logo from "../../assets/logo12.png";
import '@fortawesome/fontawesome-free/css/all.min.css';

function Home() {
	const [show, setShow] = useState(false); 
  const handleShow = () => setShow(true);
 
  const ModalHeader={
	borderBottom: "none",
  }

  return (
	<>
	<Header/>
	<section className=''>
     <div>
		<div className="banner-row" style={{backgroundImage: `url(${bannerImage})`}}>
			<div className="container">
				<div className="row">
					<div className="col-lg-7 col-md-6">
						<div className="banner-text">
							<h1>Design your own Solar System and save 30 to 40 percent</h1>
							<p>A buck ten per wattt</p>
							<a href="#" className="btn btn-primary" onClick={handleShow}>Find Out How</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	</section>


	{/* <section className='py-100 head1'>
  <div className='questions '>
    <h2 className='text-center'>
      In thinking about solar it is useful to ask yourself some basic questions
    </h2>
    <h4 className='text-center'>
      There are many issues that come to mind....
    </h4>

    <div className='container'>
      <div className='row'>
        <div className='col-md-6'>
          <img src={question} alt="Questions about solar" className="img-fluid" />
        </div>
        <div className='col-md-6'>
          <ul>
            <li>Doing the right thing for the environment</li>
            <li>Saving money on the electricity bill</li>
            <li>The return on the investment (how long before breaking even?)</li>
            <li>How best to finance the cost</li>
            <li>The need for a home battery backup</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section> */}
	
	<section className='py-100 head1'>
  <div className='questions'>
    <h2 className='text-center'>
      In thinking about solar it is useful to ask yourself some basic questions
    </h2>
    <h4 className='text-center'>
      There are many issues that come to mind....
    </h4>

    <div className='container'>
      <div className='row'>
        <div className='col-md-6'>
          <img src={question} alt="Questions about solar" className="img-fluid" />
        </div>
        <div className='col-md-6'>
          <ul className='custom-list'>
            <li className='custom-list-item'>Doing the right thing for the environment</li>
            <li className='custom-list-item'>Saving money on the electricity bill</li>
            <li className='custom-list-item'>The return on the investment (how long before breaking even?)</li>
            <li className='custom-list-item'>How best to finance the cost</li>
            <li className='custom-list-item'>The need for a home battery backup</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

	
<section className='py-100'>
  <div className='container'>
    <div className='row'>
      <div className='col-md-6 mt-3'>
        <h4>This All-in-one Battery backup, having equivalent capacity to a Tesla PowerWall.</h4>
        <h4>The unit costs $6,500 installed and will power most homes for 24 hours.</h4>
        <h4 className='mt-3'>Uses: Load shifting, Emergency power.</h4>
      </div>
      <div className='col-md-6 alls'>
        <div className="image-container">
          <img src={allone} alt="all in one" className="allin" />
          <div className="image-caption">
            Also available in a smaller 5 kWh size<br />
            Battery is charged by the grid
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section className='py-100  cardss'>
  <div className="container">
    <h2 className='mb-4 mt-4 text-center bess'>
      About All-in-one Battery Energy Storage Systems (BESS for short)
    </h2>
    <div className="row">
      <div className="col-md-3 mt-2">
        <div className="card text-center" style={{ width: '18rem', height: '100%' }}>
          <img src={safety} className="card-img-top mx-auto" alt="Card 1" style={{ width: '50%' }} />
          <div className="card-body">
           <p></p>
		   <p></p>

		    <h5 className="card-text">Most grids are pretty reliable, and even when it fails the average down time is only 45 minutes.</h5>
          </div>
        </div>
      </div>
      <div className="col-md-3 mt-2">
        <div className="card text-center" style={{ width: '18rem', height: '100%' }}>
          <img src={watch} className="card-img-top mx-auto" alt="Card 2" style={{ width: '50%' }} />
          <div className="card-body">
		  <p></p>
		  <p></p>

            <h5 className="card-text">On rare occasions the outage may last more than 45 minutes, especially in times of extreme heat or cold when the grid is stressed.</h5>
          </div>
        </div>
      </div>
      <div className="col-md-3 mt-2">
        <div className="card text-center" style={{ width: '18rem', height: '100%' }}>
          <img src={thunder} className="card-img-top mx-auto" alt="Card 3" style={{ width: '50%' }} />
          <div className="card-body">
		  <p></p>
		  <p></p>
            <h5 className="card-text">Your home router and security camera will be disabled during the time of the outage.</h5>
          </div>
        </div>
      </div>
      <div className="col-md-3 mt-2">
        <div className="card text-center" style={{ width: '18rem', height: '100%' }}>
          <img src={speaker} className="card-img-top mx-auto" alt="Card 4" style={{ width: '50%' }} />
          <div className="card-body">
		  <p></p>
		  <p></p>
            <h5 className="card-text">Generators are cheaper than Lithium batteries, but your neighbors may not appreciate the noise.</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section className='py-100'>
<div className='container'>
	<div className='row'>
		<div className='col-md-6 mt-3'>
			<h4> If you want the to ensure against grid outages lasting more than an hour.</h4>
			<h4>Opt for a battery backup that uses solar panels to charge the battery.</h4>
			<h4>No space indors? We will install a vertsion that works outdoors.  </h4>
			<h4>You are the only one that can make the decsion of which, if any, of these options is best.</h4>
			<h4>The BessAndSolar platform allows you to explore and configure a system that fits your needs or goals.</h4>
			<h4>No high-pressure salesman will visit you and meke these decisions for you</h4>
		</div>
		<div className='col-md-6'>
		<img src={homepanel} alt="all in one" className="panel" />
		</div>
	</div>
</div>
</section>	

<section className='py-100 graphss'>
<div className='container'>
	<div className='row'>
		<div className='col-md-8 mt-3'>
			<h4 className='head12' > To get a rough idea of the tradeoffs in cost our platform will take your input and  and instantly compute a rough estimate.  </h4>
			<h4 className='head12' >The ROI is computed for two cases: one without any tax benefits, the other with the current government incentives.  </h4>
		</div>
		<div className='col-md-4'>
		<img src={graph} alt="all in one" className="graph" />

		</div>
	</div>
	<h3 className='mt-3 graphz' >You can expext to pay 30 to 40 percent less for your solar system.</h3>
</div>
</section>	


<section className='py-100 head1'>
  <h2 className='text-center possib mb-4'>How is this possible?</h2>
  <div className='container'>
    <div className='row'>
      <div className='col-md-6'>
        <h4>Savings come from a variety of sources:</h4>
        <h4>We ask you to do some of the work:</h4>
        <div className="card border-success mb-3" style={{ maxWidth: '35rem',borderRadius: '4'  }}>
          <div className="card-body text-success">
       
		  <ul>
            <li>Take photos of the main service panel
				and of the exterior of the home.  </li>

            <li>Take photos of the roof rafters fhat arevisible in the attic.</li>

            <li>If you think you're up to installing the panels yourself, we will provide an expertfor on-the-job training.</li>
          </ul>

          </div>
          <div className="card-footer"></div>
        </div>
      </div>
	  <div className='col-md-6'>
		<h4 className='mt-4' >* We have no salespeople, one of the large contributors to cost</h4>
		<h4 className='mt-4'  >* We buy the equipment from top-tier suppliers at wholesale price and pass on the savings to you</h4>
		<h4 className='mt-4'  >* We are a flat organization with little corporate overhead. </h4>
		<h4 className='mt-4'  >* We have automated the design to avoid the need for on-the-premise solar designer </h4>
	  </div>
    </div>
	<h3 className='text-center' >Come on... This is too good to be true...</h3>
  </div>
</section>
	


<section className='timeline-section py-100'>
   <h2 className='text-center possib mb-4'>How does this work?</h2>

   <div className='container contt'>
      <div className='timeline'>
         {/* Step 1 */}
         <div className='timeline-item'>
            <div className='circle'>1</div>
            <div className='content'>
               <h4>Tap on My Solar Design menu item</h4>
            </div>
         </div>

         {/* Step 2 */}
         <div className='timeline-item'>
            <div className='circle'>2</div>
            <div className='content'>
               <h4>Fill in the form with the basic information requested</h4>
               <p>Your zip code, Energy consumed, etc</p>
            </div>
         </div>

         {/* Step 3 */}
         <div className='timeline-item'>
            <div className='circle'>3</div>
            <div className='content'>
               <h4>Decide on a Battery Backup</h4>
               <ul>
                  <li>I need only an All-in-one battery (Lithium)</li>
                  <li>I'll add battery backup later</li>
                  <li>I only need roof solar panels; no battery backup</li>
               </ul>
            </div>
         </div>

         {/* Final Blue Circle */}
         <div className='final-circle'></div>
      </div>
	  <h3 className='text-center'></h3>
   </div>
</section>



<section className="bg-dark text-light py-4">
  <div className="container">
    <div className="row">
      <div className="col-md-4 text-center mb-4 mb-md-0">
        <img
          src={logo}
          alt="Bees And Solar Logo"
          className="mb-3"
          style={{ maxWidth: "150px" }}
        />
        <div>
          <button className="btn btn-success mt-2">Contact Us</button>
        </div>
      </div>

      <div className="col-md-4 text-center text-md-left mb-2 mb-md-0">
        <h5 className='touch'>GET IN TOUCH</h5>
        <p className="mb-1">COMMERCIAL SOLAR INFORMATION</p>
        <p className="text-warning mb-3">(210) 555-0132</p>
        <p className="mb-1">INVESTOR/ CORPORATE INFORMATION</p>
        <p className="text-warning mb-3">(210) 555-0444</p>
        <div className="d-flex justify-content-center justify-content-md-start mt-1">
          <a href="#" className="text-light me-3">
            <i className="fab fa-facebook fa-lg"></i>
          </a>
          <a href="#" className="text-light me-3">
            <i className="fab fa-twitter fa-lg"></i>
          </a>
          <a href="#" className="text-light me-3">
            <i className="fab fa-linkedin fa-lg"></i>
          </a>
          <a href="#" className="text-light">
            <i className="fab fa-instagram fa-lg"></i>
          </a>
        </div>
      </div>

      {/* Links and Copyright */}
      <div className="col-md-4  text-md-right">
        <a href="#" className="text-light d-block mb-2">Privacy Policy</a>
        <a href="#" className="text-light d-block mb-2">Terms of Service</a>
        <p className="mb-2">COPYRIGHT © 2024. ALL RIGHTS RESERVED</p>
        <a href="#" className="text-light">Back To Top ↑</a>
      </div>
    </div>
  </div>
</section>

	
	<Modal
        size="xl"
        show={show}
		className='solar-model'
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton style={ModalHeader}>
        </Modal.Header>
        <Modal.Body>
			<div className='models-wrapper'>
				<div className='model-image-container'>
					<img src={SolarModelImage}/>
				</div>
			<div className='model-content-box'>
			<p>Did you know that 60 percent of the cost  solar installation is for sales and overhead?</p>
			<p>Design your own system and eliminate this overhead.</p>
			<p>Using Our AI-assisted SolarDesigner you do not have to be an engineer to design a solar system.</p>
			<p>Our easy-to-follow User Interface will step you through the process. If you are comfortable with the Google Earth user interface you are ready to take control of your solar project.</p>
			<p>The preliminary design is free. We provide you with all you need, from a complete list of the number of panels, wiring, inverter, junction boxes, breakers, prices for each component, instructions, and the permit application to submit to your City Building Department.</p>
			<p>And that is not all.... We will also finance your system and assign a project manager to oversee the installation, if you prefer to have a Certified Solar Installer do the work</p>
			<p>We encourage you to shop around and compare our offering. You will not find a better deal. The competition, after all, has all that overhead to cover.</p>
			<p>By designing your own system you cut the cost by 30 to 50 percent. At no risk.</p>
			</div>
			</div>
		</Modal.Body>
      </Modal>
	</>
  );
}

export default Home;
