// import React from "react";
// import "./GridTiedPopup.css";

// const GridTiedPopup = ({ design, onClose }) => {
//   const isEditMode = design && design.name !== undefined;

//   return (
//     <div className="grid-tied-overlay">
//       <div className="grid-tied-content">
//         <div className="grid-tied-header">
//           <h2>GridTiedWBattery</h2>
//           <p>Please add or change the components for each component type</p>
//           <button className="grid-tied-close-button" onClick={onClose}>
//             &times;
//           </button>
//         </div>
//         <div className="grid-tied-body">
//           <table className="grid-tied-table">
//             <thead>
//               <tr>
//                 <th>Type</th>
//                 <th>Brand</th>
//                 <th>Model</th>
//                 <th>Cost</th>
//                 <th>Qty</th>
//                 <th>Vendor</th>
//                 <th>VendorUrl</th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <td>PV</td>
//                 <td>Canadian Solar</td>
//                 <td>CSW-405</td>
//                 <td>$183</td>
//                 <td>8</td>
//                 <td>Signature Solar</td>
//                 <td>
//                   <button className="edit-button">&#9998;</button>
//                   <button className="delete-button">&#10006;</button>
//                 </td>
//               </tr>
//               <tr>
//                 <td>ISD</td>
//                 <td>NEP</td>
//                 <td>PVG-1</td>
//                 <td>$666</td>
//                 <td>8</td>
//                <td>Alibaba</td>
//                 <td>
//                   <button className="edit-button">&#9998;</button>
//                   <button className="delete-button">&#10006;</button>
//                 </td>
//               </tr>
//               <tr>
//                 <td>Hybrid Inverter</td>
//                 <td>DEYE</td>
//                 <td>SK Hybrid</td>
//                 <td>$1540</td>
//                 <td>1</td>
//                 <td>Alibaba</td>
//                 <td>
//                   <button className="edit-button">&#9998;</button>
//                   <button className="delete-button">&#10006;</button>
//                 </td>
//               </tr>
//               {/* Add more rows as needed */}
//             </tbody>
//           </table>
//         </div>
//         <div className="grid-tied-actions">
//           <button
//             className="grid-tied-done-button"
//             onClick={onClose}  
//           >
//             Done
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default GridTiedPopup;






// import React, { useEffect, useState } from "react";
// import "./GridTiedPopup.css";

// const GridTiedPopup = ({ versionId, onClose }) => {
//   const [versionDetails, setVersionDetails] = useState(null);

//   useEffect(() => {
//     if (versionId) {
//       fetchVersionDetails(versionId);
//     }
//   }, [versionId]);

//   const fetchVersionDetails = async (id) => {
//     try {
//       const response = await fetch(`https://api-dev.bessandsolar.com/api/design/get-version-details?versionId=${id}`, {
//         method: 'GET',
//         headers: {
//           'accept': 'text/plain',
//         },
//       });
//       if (!response.ok) {
//         throw new Error(`HTTP error! Status: ${response.status}`);
//       }
//       const data = await response.json();
//       setVersionDetails(data.result);
//     } catch (error) {
//       console.error('Error fetching version details:', error);
//     }
//   };

//   const truncateUrl = (url) => {
//     if (url && url.length > 100) {
//       return url.substring(0, 100) + '...';
//     }
//     return url;
//   };

//   return (
//     <div className="grid-tied-overlay">
//       <div className="grid-tied-content">
//         <div className="grid-tied-header">
//           <h2>GridTiedWBattery</h2>
//           <p>Please add or change the components for each component type</p>
//           <button className="grid-tied-close-button" onClick={onClose}>
//             &times;
//           </button>
//         </div>
//         <div className="grid-tied-body">
//           {versionDetails ? (
//             <table className="grid-tied-table">
//               <thead>
//                 <tr>
//                   <th>Type</th>
//                   <th>Brand</th>
//                   <th>Model</th>
//                   <th>Cost</th>
//                   <th>Qty</th>
//                   <th>Vendor</th>
//                   <th>VendorUrl</th>
//                   <th></th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {versionDetails.map((detail) => (
//                   <tr key={detail.designComponentId}>
//                     <td>{detail.typeDescription}</td>
//                     <td>{detail.brand}</td>
//                     <td>{detail.model}</td>
//                     <td>{detail.price}</td>
//                     <td>{detail.qty}</td>
//                     <td>{detail.vendor || 'N/A'}</td>
//                     <td className="long-url">
//                       {detail.vendorUrl ? (
//                         <a href={detail.vendorUrl} target="_blank" rel="noopener noreferrer">
//                           {truncateUrl(detail.vendorUrl)}
//                         </a>
//                       ) : 'N/A'}
//                     </td>
//                     <td>
//                       <button className="edit-button">&#9998;</button>
//                       <button className="delete-button">&#10006;</button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           ) : (
//             <p>Loading details...</p>
//           )}
//         </div>
//         <div className="grid-tied-actions">
//           <button className="grid-tied-done-button" onClick={onClose}>
//             Done
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default GridTiedPopup;








import React, { useEffect, useState } from "react";
import "./GridTiedPopup.css";

const GridTiedPopup = ({ versionId, onClose }) => {
  const [versionDetails, setVersionDetails] = useState(null);
  const [showComponentModal, setShowComponentModal] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(null);

  const components = [
    { name: 'Canadian Solar 405W [Other parameters]', id: 1 },
    { name: 'Canadian Solar 260W [Other parameters]', id: 2 },
    { name: 'Canadian Solar 220W [Other parameters]', id: 3 },
    { name: 'Canadian Solar 440W [Other parameters]', id: 4 },
  ];

  useEffect(() => {
    if (versionId) {
      fetchVersionDetails(versionId);
    }
  }, [versionId]);

  const fetchVersionDetails = async (id) => {
    try {
      const response = await fetch(`https://api-dev.bessandsolar.com/api/design/get-version-details?versionId=${id}`, {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setVersionDetails(data.result);
    } catch (error) {
      console.error('Error fetching version details:', error);
    }
  };

  const truncateUrl = (url) => {
    if (url && url.length > 100) {
      return url.substring(0, 100) + '...';
    }
    return url;
  };

  const handleComponentClick = (component) => {
    setSelectedComponent(component);
  };

  const handleSelectClick = () => {
    console.log(`Selected Component: ${selectedComponent?.name}, Quantity: ${document.getElementById('quantity').value}`);
    // Handle select action
    setShowComponentModal(false); // Close modal after selection
  };

  return (
    <div className="grid-tied-overlay">
      <div className="grid-tied-content">
        <div className="grid-tied-header">
          <h2>GridTiedWBattery</h2>
          <p>Please add or change the components for each component type</p>
          <button className="grid-tied-close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="grid-tied-body">
  {versionDetails && (
    <table className="grid-tied-table">
      <thead>
        <tr>
          <th>Type</th>
          <th>Brand</th>
          <th>Model</th>
          <th>Cost</th>
          <th>Qty</th>
          <th>Vendor</th>
          <th>VendorUrl</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {versionDetails.map((detail) => (
          <tr key={detail.designComponentId}>
            <td>{detail.typeDescription}</td>
            <td>{detail.brand}</td>
            <td>{detail.model}</td>
            <td>{detail.price}</td>
            <td>{detail.qty}</td>
            <td>{detail.vendor || 'N/A'}</td>
            <td className="long-url">
              {detail.vendorUrl ? (
                <a href={detail.vendorUrl} target="_blank" rel="noopener noreferrer">
                  {truncateUrl(detail.vendorUrl)}
                </a>
              ) : 'N/A'}
            </td>
            <td>
              <button className="edit-button" onClick={() => setShowComponentModal(true)}>&#9998;</button>
              <button className="delete-button">&#10006;</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )}
</div>


        <div className="grid-tied-actions">
          <button className="grid-tied-done-button" onClick={onClose}>
            Done
          </button>
        </div>
      </div>

      {/* Component Selection Modal */}
      {showComponentModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Please select a component</h2>
              <button className="close-button" onClick={() => setShowComponentModal(false)}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <ul className="component-list">
                {components.map((component) => (
                  <li
                    key={component.id}
                    className={`component-item ${selectedComponent?.id === component.id ? 'selected' : ''}`}
                    onClick={() => handleComponentClick(component)}
                  >
                    <span>{component.name}</span>
                    <button className="datasheet-button">Datasheet</button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="modal-footer">
              <div className="quantity-input">
                <label htmlFor="quantity">Quantity:</label>
                <input type="number" id="quantity" placeholder="Enter quantity" />
              </div>
              <button className="select-button" onClick={handleSelectClick}>Select</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GridTiedPopup;
