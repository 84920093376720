import { GoogleMap, Marker, PolygonF, Polyline, StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api';
import * as React from 'react';
import { Button, Input, Radio, RadioGroup, FormControlLabel, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import './Sidebar.css';
const Map = () => {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyB5fF9C_VlMQmTOIrK4_bgCzwEukEHqsuE",
    libraries: ["places","geometry"],
  })


  const [model, setModel] = useState('Canadian Solar 410W');
  const [orientation, setOrientation] = useState('portrait');
  const [address, setAddress] = useState('');

  const [markers, setMarkers] = useState([]);
  const [places, setPlaces] = useState([]); 
  const [map, setMap] = useState(null)
  const [searchBox, setSearchBox] = useState(null);
  const [polygonPaths, setPolygonPaths] = useState([]);
  const [searchedLocationMarker, setSearchedLocationMarker] = useState(null);
  const initialCenter = { lat: 40.688386661759274, lng: -73.93344666237486 };
  const [center, setCenter] = useState(initialCenter);
  const [panelOrientation , setPanelOrientation ] = useState(0)
  const [isPanelButtonClicked, setIsPanelButtonClicked] = useState(false);
  const [inputText, setInputText] = useState(50);
  const [panelAdded, setPanelAdded ]= useState(0);
  const [polygonStartPoints, setPolygonStartPoints] = useState([]);
  const geometryLibrary = useRef(null);
  const [ridgeLinePoints, setRidgeLinePoints] = useState([]);
  console.log("fffffffffff-----------",ridgeLinePoints)
  const [isRidgeLineButtonClicked, setIsRidgeLineButtonClicked] = useState(false);
  console.log("sssss---------",isRidgeLineButtonClicked)
  const [azimuthDegrees, setAzimuthDegrees] = useState(0);
  



  const handleModelChange = (event) => {
    setModel(event.target.value);
  };

  const handleOrientationChange = (event) => {
    setOrientation(event.target.value);
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };



  const addMarker = (e) => {
    if(isRidgeLineButtonClicked && ridgeLinePoints.length < 2) {
      setRidgeLinePoints([...ridgeLinePoints, { lat: e.latLng.lat(), lng: e.latLng.lng() }]);
    } else {
      setMarkers([...markers, { lat: e.latLng.lat(), lng: e.latLng.lng() }]);
    }
   
  };

  const handleSaveButtonClick = () => {
    const paths = markers.map(marker => ({ lat: marker.lat, lng: marker.lng }));
    console.log("ppppp", paths);
   
    setPolygonPaths([...polygonPaths, paths]); 
    
  };

  const handleRidgeLineButtonClick = () => {
    if(isRidgeLineButtonClicked == true) {
      setRidgeLinePoints([]);
     setIsRidgeLineButtonClicked(false);
    } else {
      setIsRidgeLineButtonClicked(true);

    }

    //  setIsRidgeLineButtonClicked(!isRidgeLineButtonClicked);
     

  }

  const handleAzimuthChange = (event) => {
    setAzimuthDegrees(event.target.value);
  }
  const handlePlacePanelButtonClick = () => {
    console.log("place panel clicked");
    
    setIsPanelButtonClicked(false);
    setTimeout( ()=>{
      setIsPanelButtonClicked(true);
      if (markers.length > 0) {
        const bottomLeftPoint = getBottomLeftPoint(markers);
        setPolygonStartPoints([...polygonStartPoints, { lat: bottomLeftPoint.lat, lng: bottomLeftPoint.lng}]);
      }
      // setIsPanelButtonClicked(false);
    }, 500);
    
   // setMarkers([]);

};


  const handleClearButton = () => {
    setMarkers([]);
    setPolygonPaths([]);
    setPolygonStartPoints([]);
    setRidgeLinePoints([]);
    setIsRidgeLineButtonClicked(false);
    
    setMap('')
    setCenter({
      lat: "",
      lng: "",
  });
  setIsPanelButtonClicked(false);

    setMap({
      options: {
          strokeColor: 'rgba(176, 190, 197, 0.5)',
          strokeOpacity: 0.9,
          strokeWeight: 1,
          fillColor: 'rgba(176, 190, 197, 0.5)',
          fillOpacity: 0.9
      }
  });
  };
  
// useEffect(() => {
//   if (window.google && window.google.maps && window.google.maps.geometry) {
//     const spherical = window.google.maps.geometry.spherical;
//     const geometryLibraryRef = window.google.maps.geometry;
//     geometryLibrary.current = geometryLibraryRef;
//     if (isPanelButtonClicked) {
//       showSolarPotential(spherical, markers);
//     }
//   }
// }, [isPanelButtonClicked, panelOrientation, inputText, isRidgeLineButtonClicked, ridgeLinePoints, azimuthDegrees]);
  
useEffect(() => {
  if (isLoaded && window.google && window.google.maps && window.google.maps.geometry) {
    const spherical = window.google.maps.geometry.spherical;
    const geometryLibraryRef = window.google.maps.geometry;
    geometryLibrary.current = geometryLibraryRef;
    if (isPanelButtonClicked) {
      showSolarPotential(spherical, markers);
    }
  }
}, [isLoaded, isPanelButtonClicked, panelOrientation, inputText, isRidgeLineButtonClicked, ridgeLinePoints, azimuthDegrees]);


  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, [center])



  const onMarkerDragEnd = (index, e) => {
    const newMarkers = [...markers];
    newMarkers[index] = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    setMarkers(newMarkers);
  };


  const onSearchBoxLoad = (ref) => {
    setSearchBox(ref);
  };


const onPlacesChanged = async () => {
  const newPlaces = searchBox.getPlaces();
  setPlaces(newPlaces);
  if (newPlaces.length > 0 && map) {
    const place = newPlaces[0];
    const location = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    };
    setCenter(location);
    setSearchedLocationMarker(location);
   } 
};


const searchedLocationIcon = {
  url: 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png', 
};
function isPointInsidePolygon(point, polygonPoints)  {
  const googleMaps = window.google.maps;

  const pointLatLng = new googleMaps.LatLng(point.lat, point.lng);

  const polygon = new googleMaps.Polygon({
    paths: polygonPoints
  });

  return googleMaps.geometry.poly.containsLocation(pointLatLng, polygon);
};


function computeOffset(center, distanceMeters, angleDegrees) {
    const R = 6378137; 
    const lat1 = center.latitude * Math.PI / 180; 
    const lng1 = center.longitude * Math.PI / 180; 
    const dOverR = distanceMeters / R;


    const lat2 = Math.asin(
        Math.sin(lat1) * Math.cos(dOverR) +
        Math.cos(lat1) * Math.sin(dOverR) * Math.cos(angleDegrees * Math.PI / 180)
    );

    const lng2 = lng1 + Math.atan2(
        Math.sin(angleDegrees * Math.PI / 180) * Math.sin(dOverR) * Math.cos(lat1),
        Math.cos(dOverR) - Math.sin(lat1) * Math.sin(lat2)
    );

    return {
        lat: lat2 * 180 / Math.PI, 
        lng: lng2 * 180 / Math.PI 
    };
}

const addDistanceToLatitude = (latitude, distanceMeters) => {
  const R = 6371000;

  const deltaLat = (distanceMeters / R) * (180 / Math.PI);

  const newLat = latitude + deltaLat;

  return newLat;
};

const addDistanceToLongitude = (latitude, longitude, distanceMeters) => {
  const R = 6371000;

  const lonPerMeter = 1 / (R * Math.cos(latitude * Math.PI / 180));

  const deltaLon = distanceMeters * lonPerMeter;

  const newLon = longitude + deltaLon;

  return newLon;
};


const getBottomLeftPoint = (points) => {
  // Initialize the bottom left point as the first point in the array
  let bottomLeftPoint = points[0];

  // Iterate through the array of points
  for (let i = 1; i < points.length; i++) {
      if (points[i].lat < bottomLeftPoint.lat || (points[i].lat === bottomLeftPoint.lat && points[i].lng < bottomLeftPoint.lng)) {
          bottomLeftPoint = points[i];
      }
  }

  return bottomLeftPoint;
};

const isPanelInsidePolygon = (cornerCoordinates, polygonValue) => {

  const allInside = cornerCoordinates.every((coord) =>
          isPointInsidePolygon(coord, polygonValue)
     );
   return allInside  
}



const getBoundingBox = (coords) => {
  const lats = coords.map(coord => coord.lat);
  const lngs = coords.map(coord => coord.lng);
  return {
    minLat: Math.min(...lats),
    maxLat: Math.max(...lats),
    minLng: Math.min(...lngs),
    maxLng: Math.max(...lngs),
  };
};

  const rotateVertex = (vertex, radians, origin) => {
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);
    const translatedX = vertex.lng - origin.lng;
    const translatedY = vertex.lat - origin.lat;
    const rotatedX = translatedX * cos - translatedY * sin;
    const rotatedY = translatedX * sin + translatedY * cos;
    return {
      lat: rotatedY + origin.lat,
      lng: rotatedX + origin.lng
    };
  };
  const getRotatedPolygon = (polygon, angle, origin) => {
    return polygon.map(point => rotateVertex(point, angle, origin));
  };
  const calculateAngle = (point1, point2) => {
    const dx = point2.lng - point1.lng;
    const dy = point2.lat - point1.lat;
    const radians = Math.atan2(dy, dx);
    return radians;
  };
  const generatePaths = (
    widthInMeters,
    heightInMeters,
    azimuthDegrees,
  ) => {
    const meterToLatLng = 0.000009;
    const xSeparation = 0.04;
    const ySeparation = 0.04;
    var rotationAngle = 0;
    if (ridgeLinePoints.length == 2) {
      rotationAngle = calculateAngle(ridgeLinePoints[0], ridgeLinePoints[1]);
    }
    rotationAngle += parseFloat(azimuthDegrees);
    const widthInDegrees = parseFloat((widthInMeters * meterToLatLng).toFixed(8));
    const heightInDegrees = parseFloat((heightInMeters * meterToLatLng).toFixed(8));
    const rotatedPolygon = getRotatedPolygon(polygonPaths[0], -rotationAngle, polygonPaths[0][0]);
  // Get bounding box for the large polygon
    const boundingBox = getBoundingBox(rotatedPolygon);
    const { minLat, maxLat, minLng, maxLng } = boundingBox;
    let currentLat = parseFloat(minLat.toFixed(8));
    let currentLng = parseFloat(minLng.toFixed(8));
    let longValue = 0;
    let tilesList = [];
    while (currentLat <= maxLat) {
      while (currentLng <= maxLng) {
        const smallPolygonCoords = [
          { lat: currentLat, lng: currentLng },
          { lat: currentLat + heightInDegrees, lng: currentLng },
          { lat: currentLat + heightInDegrees, lng: currentLng + widthInDegrees },
          { lat: currentLat, lng: currentLng + widthInDegrees },
        ];
        if (isPanelInsidePolygon(smallPolygonCoords, rotatedPolygon)) {
          tilesList.push(smallPolygonCoords)
        } else {
          console.log("panel not place");
        }
        currentLng = parseFloat((currentLng + widthInDegrees + (xSeparation * meterToLatLng)).toFixed(8));
        longValue = boundingBox.minLng
      }
     // Reset longitude to start position and move to the next row
      currentLng = longValue;
      currentLat = parseFloat((currentLat + heightInDegrees + (ySeparation * meterToLatLng)).toFixed(8)); // This factor can be adjusted based on spacing needs
    }
    let paths = tilesList.map(item => (
    <PolygonF
      key={`${item.lat}-${item.lng}`} // Ensure unique key for each polygon
      path={getRotatedPolygon(item, rotationAngle, polygonPaths[0][0])}
      options={{
        strokeColor: "#B0BEC5",
        strokeOpacity: 0.9,
        strokeWeight: 1,
        fillColor: "black",
        fillOpacity: 0.9,
      }}
    />
  ));
    return paths;
  };

  const showSolarPotential = (spherical, panelCount) => {
    // const palette = createPalette(panelsPalette, 256).map(rgbToColor);
    let heightInMeters = 1.879;
    let widthInMeters = 1.045;
    if (panelOrientation === 90) {
      widthInMeters = 1.879;
      heightInMeters = 1.045;
    }
       
    const paths = generatePaths(widthInMeters,heightInMeters,azimuthDegrees, panelCount,markers);
   
    setMap({
        paths,
        options: {
            strokeColor: '#B0BEC5',
            strokeOpacity: 0.9,
            strokeWeight: 5,
            fillColor: 'black',
            fillOpacity: 0.9
        }
    });
     setMarkers([]);
  };
  
  return (
    <div style={{ position: 'relative', height: '100vh' }}>
      <div style={{ position: 'absolute', top: '8%', zIndex: '1', display: 'flex', alignItems: 'center', transform: 'translate(-50%, -50%)', left: '50%', padding: '6px 11px' }}>
      </div>
      
      <div className="sidebar">
      <h2>Building Address</h2>
      <StandaloneSearchBox
              onLoad={onSearchBoxLoad}
              onPlacesChanged={onPlacesChanged}
            >
      <Input 
        placeholder="Enter an address..." 
        value={address} 
        // onLoad={onSearchBoxLoad}
        onPlacesChanged={onPlacesChanged}
        onChange={handleAddressChange}
        fullWidth 
        style={{ marginBottom: '10px' }}
      />
       </StandaloneSearchBox>
      <Button variant="contained" style={{ marginBottom: '20px', width: '30%', marginRight:'68%' }}>Go</Button>

      <h3 >Panels Features</h3>
      <FormControl fullWidth style={{ marginBottom: '10px' }}>
        <InputLabel></InputLabel>
        <Select value={model} onChange={handleModelChange}>
          <MenuItem value="Canadian Solar 410W">Canadian Solar 410W</MenuItem>
        </Select>
      </FormControl>

      <Input 
        placeholder="Panels amount..." 
        fullWidth 
        style={{ marginBottom: '10px' }}
      />
      <Input 
        placeholder="Panels separation..." 
        fullWidth 
        style={{ marginBottom: '10px' }}
      />

      <h3>Orientation</h3>
      <RadioGroup
         row
        value={panelOrientation.toString()} 
         onChange={(event) => {
         const value = parseInt(event.target.value); 
          if (value === 0 || value === 90) {
            setPanelOrientation(value);
          }
         }}
        style={{ marginBottom: '20px' }}
      >
        <FormControlLabel value="0" control={<Radio color="primary" />} label="Portrait" />
        <FormControlLabel value="90" control={<Radio color="primary" />} label="Landscape" />
      </RadioGroup>

      <h3>Actions</h3>
      <Button variant="contained" style={{ marginBottom: '10px', width: '100%' }} onClick={handleSaveButtonClick} >Select Area</Button>
      <Button variant="contained" style={{ marginBottom: '10px', width: '100%' }} >Set Direction</Button>
      <Button variant="contained" style={{ marginBottom: '10px', width: '100%' }}>Show Irradiance Map</Button>
      <Button variant="contained" style={{ marginBottom: '10px', width: '100%' }}onClick={handlePlacePanelButtonClick} >Place Panels</Button>
      <Button variant="contained" style={{ marginBottom: '10px', width: '100%' }} onClick={handleClearButton} >Clear</Button>

    </div>


      <div style={{ height: '100%', width: '100%' }}>
        {!isLoaded ? (
          <h1>Loading...</h1>
        ) : (
          <GoogleMap
            mapContainerStyle={{ height: '100%', width: '100%' }}
            center={center}
            mapTypeId="satellite"
            tilt={0}
            onLoad={onLoad}
            onClick={addMarker}
            compassEnabled={true}
          >
            {searchedLocationMarker && (
              <Marker
                position={searchedLocationMarker}
                icon={searchedLocationIcon}
                draggable={false} 
              />
            )}
            <Marker position={initialCenter} />
            {markers.length > 0 && markers.map((marker, index) => (
              <Marker
                key={index}
                position={{ lat: marker.lat, lng: marker.lng }}
                draggable={true}
                onDragEnd={(e) => onMarkerDragEnd(index, e)}
              />
            ))}
             {ridgeLinePoints.length >= 1 && (
              <Polyline
                path={ridgeLinePoints}
                options={{
                  strokeColor: 'blue',
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                }}
              />
            )}
        {polygonPaths.length > 0 && polygonPaths.map((polygonPath, index) => (
              <PolygonF
                path={polygonPath}
                options={{
                  strokeColor: '#FF0000',
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: 'red',
                  fillOpacity: 0.35
                }}
              />
            ))}
            {markers.length >= 1 && (
              <Polyline
                path={markers}
                options={{
                  strokeColor: '#FF0000',
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                }}
              />
            )}
            {map && map.paths ? map.paths.map(ele => ele) : null}
          </GoogleMap>
        )}
      </div>
    </div>
  );
  
}
export default Map;