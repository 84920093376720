import { createSlice } from '@reduxjs/toolkit'
import { registerUser, userLogin , saveReport} from './authAction'


const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null

const initialState = {
  loading: false,
  userInfo: null,
  userToken: userToken,
  error: null,
  success: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    // login user
    [userLogin.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.userInfo = payload
      state.userToken = payload?.userToken ?? null
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    // register user reducer...
    [registerUser.pending]: (state) => {
        state.loading = true
        state.error = null
    },
    [registerUser.fulfilled]: (state, { payload }) => {
        state.loading = false
        state.userInfo = payload
        state.userToken = payload?.userToken ?? null
    },
    [registerUser.rejected]: (state, { payload }) => {
        state.loading = false
        state.error = payload
    },
    // save report .. 
    [saveReport.pending]:(state) =>{
      state.loading = true
      state.error = null
    },
    [saveReport.fulfilled]: (state,{ payload}) =>{
      state.loading = false
      state.userInfo = payload
      state.userToken = payload?.userToken?? null
    },
    [saveReport.rejected]: (state, {payload}) =>{
      state.loading = false
      state.error = payload
    },
  },
})
export default authSlice.reducer