
// import React, { useState, useEffect } from "react";
// import './admin.css';
// import { Table, Button } from "react-bootstrap";
// import Header from "../../component/common/header/Header";
// import { FaEdit } from "react-icons/fa";
// import DesignPopup from './DesignPopup';
// import ComponentPopup from './ComponentPopup';
// import GridTiedWBatteryPopup from './GridTiedPopup.js';
// import { fetchComponents,fetchSolarDesigns,fetchDesignComponents ,fetchDesignVersions} from '../../pages/Admin/apiService.jsx'; 
// import AddDesign from './AddDesign.jsx'
// import ComponentDetails from "./ComponentDetails.jsx";

// function AdminPage() {
//   const [activeTab, setActiveTab] = useState('designs');
//   const [selectedDesign, setSelectedDesign] = useState(null);
//   const [showPopup, setShowPopup] = useState(false);
//   const [selectedDesignDetails, setSelectedDesignDetails] = useState(null);
//   const [designPageView, setDesignPageView] = useState('main');
//   const [isPopupOpen, setIsPopupOpen] = useState(false);
//   const [isGridTiedWBatteryPopupOpen, setIsGridTiedWBatteryPopupOpen] = useState(false);
//   const [components, setComponents] = useState([]);
//   const [solarDesigns, setSolarDesigns] = useState([]);
//   const [designVersions, setDesignVersions] = useState([]);
//   const [selectedDesignId, setSelectedDesignId] = useState(null);
//   const [selectedVersionId, setSelectedVersionId] = useState(null);
//   const [componentsData, setComponentsData] = useState([]);
//   const [showDesignPopup, setShowDesignPopup] = useState(false);
//   const [selectedComponent, setSelectedComponent] = useState(null); // Store selected component
//   const [isComponentDetailsOpen, setIsComponentDetailsOpen] = useState(false); // State to manage component details popup


//   const handleTabClick = (tab) => {
//     setActiveTab(tab);
//   };

//   console.log('hhhhhhhhhhhhhhh',solarDesigns)


//   const handleDesignSelection = async (design) => {
//     try {
//       const fetchedComponents = await fetchDesignComponents(design.designId);
//       setComponentsData(fetchedComponents);
//       setSelectedDesign(design.designType);
//       setSelectedDesignDetails({
//         ...design,
//         designId: selectedDesignId, 
//       });
//       setIsPopupOpen(true);
//     } catch (error) {
//       console.error('Error fetching design components:', error);
//     }
//   };
  

//   const handleNextClick = () => {
//     if (selectedDesignId) {
//       fetchDesignVersions(selectedDesignId)
//         .then(data => {
//           if (Array.isArray(data)) {
//             setDesignVersions(data);
//             setDesignPageView('detail');
//           } else {
//             console.error("Unexpected data format:", data);
//           }
//         })
//         .catch(error => console.error("Error fetching design versions:", error));
//     } else {
//       console.warn("No design selected");
//     }
//   };

  
 
  
//   const handlePrevClick = () => {
//     setDesignPageView('main');
//   };

 
  
//   const handleOpenPopup = async () => {
//     setSelectedDesignDetails(null);
//     setIsPopupOpen(true);
//     setShowDesignPopup(true);
    
  
//   };

//   const handleShowComponentDetails = (component) => {
//     setSelectedComponent(component);
//     setIsComponentDetailsOpen(true); // Open the popup
//   };

//   // Handle closing component details popup
//   const handleCloseComponentDetails = () => {
//     setIsComponentDetailsOpen(false);
//     setSelectedComponent(null); // Clear the selected component
//   };

  
//   const handleClosePopup = () => {
//     setIsPopupOpen(false);
//     setIsGridTiedWBatteryPopupOpen(false);
//     setShowDesignPopup(false);
    
//   };

//   const handleEditComponent = (component) => {
//     setSelectedComponent(component);  
//     setShowPopup(true);               
//   };


//   const handleComponentDetails = (component) => {
//     // Log the component details to the console
//     console.log('Component Details:', component);
//     // Optionally, you could also show a popup or modal with detailed information here
//   };


//   useEffect(() => {
//     if (activeTab === 'components') {
//       fetchComponents()
//         .then(data => {
//           setComponents(data);
//           console.log("Components data:", data);
//         });
//     }
//   }, [activeTab ]);

//   useEffect(() => {
//     if (activeTab === 'designs' && designPageView === 'main') {
//       fetchSolarDesigns()
//         .then(data => {
//           if (Array.isArray(data)) {
//             setSolarDesigns(data); 
//           } else {
//             console.error("Unexpected data format:", data);
//           }
//         })
//         .catch(error => console.error("Error fetching solar designs:", error));
//     }
//   }, [activeTab, designPageView]);

//   return (
//     <>
//       {/* <Header />
//       <section className="py-100">
//         <div className="container mt-4">
//           <div className="d-flex justify-content-center">
//             <ul className="nav nav-tabs">
//               <li className="nav-item">
//                 <button
//                   className={`nav-link ${activeTab === 'designs' ? 'active' : ''}`}
//                   onClick={() => handleTabClick('designs')}
//                   style={{ color: activeTab === 'designs' ? '#007bff' : '#000' }}
//                 >
//                   Designs
//                 </button>
//               </li>
//               <li className="nav-item">
//                 <button
//                   className={`nav-link ${activeTab === 'components' ? 'active' : ''}`}
//                   onClick={() => handleTabClick('components')}
//                   style={{ color: activeTab === 'components' ? '#007bff' : '#000' }}
//                 >
//                   Components
//                 </button>
//               </li>
//               <li className="nav-item">
//                 <button
//                   className={`nav-link ${activeTab === 'vendors' ? 'active' : ''}`}
//                   onClick={() => handleTabClick('vendors')}
//                   style={{ color: activeTab === 'vendors' ? '#007bff' : '#000' }}
//                 >
//                   Vendors
//                 </button>
//               </li>
//             </ul>
//           </div>
         

//           {activeTab === 'designs' && designPageView === 'main' && (
//             <div className="mt-4 main">
//               <h2 className="text-center">Solar Design Types</h2>
//               <p className="text-center">Please select a Design by tapping on the edit icon</p>
//               <Table bordered>
//                 <thead className="bg-primary text-white">
//                   <tr>
//                     <th>Design Type</th>
//                     <th>Components Description</th>
//                     <th>Number of Implementations</th>
//                     <th></th>
//                     <th></th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {solarDesigns.length > 0 ? (
//                     solarDesigns.map((design) => (
//                       <tr key={design.designId} className={selectedDesign === design.designType ? 'bg-light' : ''}>
//                         <td>{design.designType}</td>
//                         <td>{design.componentsDescription}</td>
//                         <td>{design.numberOfImplementations}</td>
//                         <td className="d-flex justify-content-center align-items-center">
//                           <FaEdit
//                             className={selectedDesign === design.designType ? 'text-success me-2' : 'text-muted me-2'}
//                             style={{ cursor: 'pointer' }}
//                             onClick={() => handleDesignSelection(design)}
//                           />
//                           <input
//                                 className="form-check-input"
//                                 type="radio"
//                                 name="radioNoLabel"
//                                 id={`radioNoLabel${design.designId}`}
//                                 value={design.designId}
//                                 aria-label="..."
//                                 onChange={() => {
//                                   setSelectedDesignId(design.designId);
//                                   console.log('Selected Design ID:', design.designId); 
//                                 }}
//                               />
//                         </td>
//                       </tr>
//                     ))
//                   ) : (
//                     <tr>
//                       <td colSpan="4" className="text-center">No designs available</td>
//                     </tr>
//                   )}
//                 </tbody>
//               </Table>
//               <div className="d-flex justify-content-end mt-3">
//                 <button className="adddesgin mx-2" onClick={handleOpenPopup}>Add Design</button>
//                 <Button variant="success" className="finbutton" onClick={handleNextClick}>Next</Button>
//               </div>
//             </div>
//           )}




//                {activeTab === 'designs' && designPageView === 'detail' && (
//                     <div className="mt-4 main">
//                       <h2 className="text-center">Design Versions</h2>
//                       <p className="text-center">Please select a version</p>
//                       <Table bordered>
//                         <thead className="bg-primary text-white">
//                           <tr>
//                             <th>Description</th>
//                             <th>Inverter</th>
//                             <th>Battery</th>
//                             <th>Panels</th>
//                             <th>PV Power</th>
//                             <th>Status</th>
//                             <th></th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {designVersions.length > 0 ? (
//                     designVersions.map((version) => (
//                       <tr key={version.versionId}>
//                         <td>{version.description}</td>
//                         <td>{version.inverter}</td>
//                         <td>{version.battery}</td>
//                         <td>{version.panels}</td>
//                         <td>{version.pvPower}</td>
//                         <td>{version.status.description}</td>                    
//                             <td className="d-flex justify-content-center align-items-center">
//                           <FaEdit 
//                             className="text-muted" 
//                             style={{ cursor: 'pointer' }} 
//                             onClick={() => {
//                               setSelectedVersionId(version.versionId);
//                               console.log('Clicked Version ID:', version.versionId); 
//                               setIsGridTiedWBatteryPopupOpen(true);
//                             }}
//                          />
//                         </td>
//                       </tr>
//                     ))
//                   ) : (
//                     <tr>
//                       <td colSpan="7" className="text-center">No versions available</td>
//                     </tr>
//                   )}
//                 </tbody>
//               </Table>
//               <div className="d-flex justify-content-between mt-3">
//                 <Button variant="secondary" className="prevbutton" onClick={handlePrevClick}>Prev</Button>
//                 <Button variant="primary" className="me-3">Add Version</Button>
//                 <Button variant="success" className="finbutton">Finish</Button>
//               </div>
//             </div>
//           )} 

// {activeTab === 'components' && (
//   <div className="mt-4 main">
//     <h2 className="text-center">Components</h2>
//     <p className="text-center">Available components</p>
//     <Table bordered>
//       <thead className="bg-primary text-white">
//         <tr>
//           <th>Type</th>
//           <th>Brand</th>
//           <th>Model</th>
//           <th>Power</th>
//           <th>Image URL</th>
//           <th>Datasheet</th>
//           <th>Details</th>
//           <th></th>
//         </tr>
//       </thead>
//       <tbody>
//         {components.length > 0 ? (
//           components.map((component) => (
//             <tr key={component._id}>
//               <td>{component.type}</td>
//               <td>{component.brand}</td>
//               <td>{component.model}</td>
//               <td>{component.power}</td>
//               <td>{component.imageUrl}</td>
//               <td>{component.datasheet}</td>
//               <td>
//               <a href=""
//                 variant="info"
//                 onClick={() => handleShowComponentDetails(component)} // Show popup
//                        >
//                 View Details
//                           </a>
//                           </td>
//               <td className="d-flex justify-content-center align-items-center">
//                 <FaEdit className="text-muted" style={{ cursor: 'pointer' }} onClick={() => handleEditComponent(component)}  />
//               </td>
//             </tr>
//           ))
//         ) : (
//           <tr>
//             <td colSpan="8" className="text-center">No components available</td>
//           </tr>
//         )}
//       </tbody>
//     </Table>
//     <div className="d-flex justify-content-end mt-3">
//       <Button variant="primary" className="mx-2" onClick={() => setShowPopup(true)}>Add Component</Button>
//       <Button variant="success" className="finbutton">Finish</Button>
//     </div>
//   </div>
// )} */}

// <Header />
//     <section className="py-100">
//       <div className="container mt-4">
//         <div className="d-flex justify-content-center">
//           <ul className="nav nav-tabs">
//             <li className="nav-item">
//               <button
//                 className={`nav-link ${activeTab === 'designs' ? 'active' : ''}`}
//                 onClick={() => handleTabClick('designs')}
//                 style={{ color: activeTab === 'designs' ? '#007bff' : '#000' }}
//               >
//                 Designs
//               </button>
//             </li>
//             <li className="nav-item">
//               <button
//                 className={`nav-link ${activeTab === 'components' ? 'active' : ''}`}
//                 onClick={() => handleTabClick('components')}
//                 style={{ color: activeTab === 'components' ? '#007bff' : '#000' }}
//               >
//                 Components
//               </button>
//             </li>
//             <li className="nav-item">
//               <button
//                 className={`nav-link ${activeTab === 'vendors' ? 'active' : ''}`}
//                 onClick={() => handleTabClick('vendors')}
//                 style={{ color: activeTab === 'vendors' ? '#007bff' : '#000' }}
//               >
//                 Vendors
//               </button>
//             </li>
//           </ul>
//         </div>

//         {/* Render Designs */}
//         {activeTab === 'designs' && designPageView === 'main' && (
//           <div className="mt-4 main">
//             <h2 className="text-center">Solar Design Types</h2>
//             <Table bordered>
//               <thead className="bg-primary text-white">
//                 <tr>
//                   <th>Design Type</th>
//                   <th>Components Description</th>
//                   <th>Number of Implementations</th>
//                   <th>Edit</th>
//                   <th>Select</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {solarDesigns.length > 0 ? (
//                   solarDesigns.map((design) => (
//                     <tr key={design.designId} className={selectedDesign === design.designType ? 'bg-light' : ''}>
//                       <td>{design.designType}</td>
//                       <td>{design.componentsDescription}</td>
//                       <td>{design.numberOfImplementations}</td>
//                       <td>
//                         <FaEdit
//                           className={selectedDesign === design.designType ? 'text-success' : 'text-muted'}
//                           style={{ cursor: 'pointer' }}
//                           onClick={() => handleDesignSelection(design)}
//                         />
//                       </td>
//                       <td>
//                         <input
//                           className="form-check-input"
//                           type="radio"
//                           name="radioNoLabel"
//                           id={`radioNoLabel${design.designId}`}
//                           value={design.designId}
//                           onChange={() => setSelectedDesignId(design.designId)}
//                         />
//                       </td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td colSpan="5" className="text-center">No designs available</td>
//                   </tr>
//                 )}
//               </tbody>
//             </Table>
//             <div className="d-flex justify-content-end mt-3">
//               <Button variant="primary" onClick={handleOpenPopup}>Add Design</Button>
//               <Button variant="success" onClick={handleNextClick}>Next</Button>
//             </div>
//           </div>
//         )}

//         {/* Render Design Versions */}
//         {activeTab === 'designs' && designPageView === 'detail' && (
//           <div className="mt-4 main">
//             <h2 className="text-center">Design Versions</h2>
//             <Table bordered>
//               <thead className="bg-primary text-white">
//                 <tr>
//                   <th>Description</th>
//                   <th>Inverter</th>
//                   <th>Battery</th>
//                   <th>Panels</th>
//                   <th>PV Power</th>
//                   <th>Status</th>
//                   <th></th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {designVersions.length > 0 ? (
//                   designVersions.map((version) => (
//                     <tr key={version.versionId}>
//                       <td>{version.description}</td>
//                       <td>{version.inverter}</td>
//                       <td>{version.battery}</td>
//                       <td>{version.panels}</td>
//                       <td>{version.pvPower}</td>
//                       <td>{version.status.description}</td>
//                       <td>
//                         <FaEdit 
//                           className="text-muted" 
//                           style={{ cursor: 'pointer' }} 
//                           onClick={() => {
//                             setSelectedVersionId(version.versionId);
//                             setIsGridTiedWBatteryPopupOpen(true);
//                           }}
//                         />
//                       </td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td colSpan="7" className="text-center">No versions available</td>
//                   </tr>
//                 )}
//               </tbody>
//             </Table>
//             <div className="d-flex justify-content-between mt-3">
//               <Button variant="secondary" onClick={handlePrevClick}>Prev</Button>
//               <Button variant="primary">Add Version</Button>
//               <Button variant="success">Finish</Button>
//             </div>
//           </div>
//         )}

//         {/* Render Components */}
//         {activeTab === 'components' && (
//           <div className="mt-4 main">
//             <h2 className="text-center">Components</h2>
//             <Table bordered>
//               <thead className="bg-primary text-white">
//                 <tr>
//                   <th>Type</th>
//                   <th>Brand</th>
//                   <th>Model</th>
//                   <th>Power</th>
//                   <th>Image URL</th>
//                   <th>Datasheet</th>
//                   <th>Details</th>
//                   <th></th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {components.length > 0 ? (
//                   components.map((component) => (
//                     <tr key={component._id}>
//                       <td>{component.type}</td>
//                       <td>{component.brand}</td>
//                       <td>{component.model}</td>
//                       <td>{component.power}</td>
//                       <td>{component.imageUrl}</td>
//                       <td>{component.datasheet}</td>
//                       <td>
//                         <a
//                           href="#"
//                           onClick={() => handleShowComponentDetails(component)}
//                         >
//                           View Details
//                         </a>
//                       </td>
//                       <td>
//                         <FaEdit
//                           className="text-muted"
//                           style={{ cursor: 'pointer' }}
//                           onClick={() => handleEditComponent(component)}
//                         />
//                       </td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td colSpan="8" className="text-center">No components available</td>
//                   </tr>
//                 )}
//               </tbody>
//             </Table>
//             <div className="d-flex justify-content-end mt-3">
//               <Button variant="primary" onClick={() => setShowPopup(true)}>Add Component</Button>
//               <Button variant="success">Finish</Button>
//             </div>
//           </div>
//         )}


//           {activeTab === 'vendors' && (
//             <div className="mt-4 main">
//               <h2 className="text-center">Vendors</h2>
//               <p className="text-center">Please provide the information of the new vendor</p>

//               <div className="row">
//                 <div className="col-md-6">
//                   <div className="mb-3">
//                     <label className="form-label">Vendor Name:</label>
//                     <input type="text" className="form-control" placeholder="Enter vendor name" />
//                   </div>
//                   <div className="mb-3">
//                     <label className="form-label">Website:</label>
//                     <input type="text" className="form-control" placeholder="Enter website URL" />
//                   </div>
//                   <Button variant="primary">Add</Button>
//                 </div>

//                 <div className="col-md-6">
//                   <h4 className="text-center">Current Vendors:</h4>
//                   <select className="form-select" size="7">
//                     <option>Canadian Solar 405W [Other parameters]</option>
//                     <option>Canadian Solar 260W [Other parameters]</option>
//                     <option>Canadian Solar 220W [Other parameters]</option>
//                     <option>Canadian Solar 440W [Other parameters]</option>
//                   </select>
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//       </section>



//          {isPopupOpen && (
//         <DesignPopup
//           design={selectedDesignDetails}
//           components={componentsData}
//           onClose={handleClosePopup}
//         />
//       )}

//         {showPopup && (
//         <ComponentPopup 
//           component={selectedComponent}  
//           onClose={() => setShowPopup(false)} 
//         />
//       )}

//          {isComponentDetailsOpen &&(
//             <ComponentDetails
//               component={selectedComponent}
//               onClose={handleCloseComponentDetails} 
//             />
//           )}

//       {isGridTiedWBatteryPopupOpen && (
//         <GridTiedWBatteryPopup 
//         versionId={selectedVersionId}
//         onClose={handleClosePopup} />
//       )}
//         {showDesignPopup && (
//             <AddDesign
//               design={solarDesigns}
//               onClose={handleClosePopup}
//             />
//           )}
//     </>
//   );
// }

// export default AdminPage;







import React, { useState, useEffect } from "react";
import './admin.css';
import { Table, Button } from "react-bootstrap";
import Header from "../../component/common/header/Header";
import { FaEdit } from "react-icons/fa";
import DesignPopup from './DesignPopup';
import ComponentPopup from './ComponentPopup';
import GridTiedWBatteryPopup from './GridTiedPopup.js';
import { fetchComponents, fetchSolarDesigns, fetchDesignComponents, fetchDesignVersions,fetchComponentDetails , fetchVendors,postVendor} from '../../pages/Admin/apiService.jsx'; 
import AddDesign from './AddDesign.jsx';
import ComponentDetails from "./ComponentDetails.jsx";
import AddComponent from './AddComponent'; 


function AdminPage() {
  const [activeTab, setActiveTab] = useState('designs');
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedDesignDetails, setSelectedDesignDetails] = useState(null);
  const [designPageView, setDesignPageView] = useState('main');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isGridTiedWBatteryPopupOpen, setIsGridTiedWBatteryPopupOpen] = useState(false);
  const [components, setComponents] = useState([]);
  const [solarDesigns, setSolarDesigns] = useState([]);
  const [designVersions, setDesignVersions] = useState([]);
  const [selectedDesignId, setSelectedDesignId] = useState(null);
  const [selectedVersionId, setSelectedVersionId] = useState(null);
  const [componentsData, setComponentsData] = useState([]);
  const [showDesignPopup, setShowDesignPopup] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(null); 
  const [isComponentDetailsOpen, setIsComponentDetailsOpen] = useState(false); 
  const [vendors, setVendors] = useState([]);
  const [newVendor, setNewVendor] = useState({ name: '', website: '' });
  const [showAddComponentPopup, setShowAddComponentPopup] = useState(false);




  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === 'vendors') {
      fetchVendors()
        .then(data => {
          setVendors(data);  
          console.log("Vendors data:", data);
        })
        .catch(error => console.error("Error fetching vendors:", error));
    }
  };

  const handleDesignSelection = async (design) => {
    try {
      const fetchedComponents = await fetchDesignComponents(design.designId);
      setComponentsData(fetchedComponents);
      setSelectedDesign(design.designType);
      setSelectedDesignDetails({
        ...design,
        designId: selectedDesignId, 
      });
      setIsPopupOpen(true);
    } catch (error) {
      console.error('Error fetching design components:', error);
    }
  };

  const handleOpenAddComponentPopup = () => {
    setShowAddComponentPopup(true);
  };
  
  const handleCloseAddComponentPopup = () => {
    setShowAddComponentPopup(false);
  };
  




  const handleVendorSave = async () => {
    try {
      await postVendor(newVendor); 
      alert("Vendor added successfully");
      fetchVendors() 
        .then(data => setVendors(data));
      setNewVendor({ name: '', website: '' }); 
    } catch (error) {
      console.error('Error saving vendor:', error);
      alert("Failed to add vendor");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewVendor(prevState => ({ ...prevState, [name]: value }));
  };


  const handleNextClick = () => {
    if (selectedDesignId) {
      fetchDesignVersions(selectedDesignId)
        .then(data => {
          if (Array.isArray(data)) {
            setDesignVersions(data);
            setDesignPageView('detail');
          } else {
            console.error("Unexpected data format:", data);
          }
        })
        .catch(error => console.error("Error fetching design versions:", error));
    } else {
      console.warn("No design selected");
    }
  };

  const handlePrevClick = () => {
    setDesignPageView('main');
  };

  const handleOpenPopup = async () => {
    setSelectedDesignDetails(null);
    setIsPopupOpen(true);
    setShowDesignPopup(true);
  };

  const handleComponentDetails = async (component) => {
    try {
      const details = await fetchComponentDetails(component.componentId);
      setSelectedComponent(details); 
      setIsComponentDetailsOpen(true); 
    } catch (error) {
      console.error('Error fetching component details:', error);
    }
  };
  

  const handleCloseComponentDetails = () => {
    setIsComponentDetailsOpen(false);
    setSelectedComponent(null); 
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setIsGridTiedWBatteryPopupOpen(false);
    setShowDesignPopup(false);
  };

  const handleEditComponent = (component) => {
    console.log("Selected Component ID:", component.componentId);  

    setSelectedComponent(component);  
    setShowPopup(true);               
  };


  

  useEffect(() => {
    if (activeTab === 'components') {
      fetchComponents()
        .then(data => {
          setComponents(data);
          console.log("Components data:", data);
        });
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === 'designs' && designPageView === 'main') {
      fetchSolarDesigns()
        .then(data => {
          if (Array.isArray(data)) {
            setSolarDesigns(data); 
          } else {
            console.error("Unexpected data format:", data);
          }
        })
        .catch(error => console.error("Error fetching solar designs:", error));
    }
  }, [activeTab, designPageView]);

  return (
    <>
      <Header />
      <section className="py-100">
        <div className="container mt-4">
          <div className="d-flex justify-content-center">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <button
                  className={`nav-link ${activeTab === 'designs' ? 'active' : ''}`}
                  onClick={() => handleTabClick('designs')}
                  style={{ color: activeTab === 'designs' ? '#007bff' : '#000' }}
                >
                  Designs
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${activeTab === 'components' ? 'active' : ''}`}
                  onClick={() => handleTabClick('components')}
                  style={{ color: activeTab === 'components' ? '#007bff' : '#000' }}
                >
                  Components
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${activeTab === 'vendors' ? 'active' : ''}`}
                  onClick={() => handleTabClick('vendors')}
                  style={{ color: activeTab === 'vendors' ? '#007bff' : '#000' }}
                >
                  Vendors
                </button>
              </li>
            </ul>
          </div>

          {activeTab === 'designs' && designPageView === 'main' && (
            <div className="mt-4 main">
              <h2 className="text-center">Solar Design Types</h2>
              <Table bordered>
                <thead className="bg-primary text-white">
                  <tr>
                    <th>Design Type</th>
                    <th>Components Description</th>
                    <th>Number of Implementations</th>
                    <th>Edit</th>
                    <th>Select</th>
                  </tr>
                </thead>
                <tbody>
                  {solarDesigns.length > 0 ? (
                    solarDesigns.map((design) => (
                      <tr key={design.designId} className={selectedDesign === design.designType ? 'bg-light' : ''}>
                        <td>{design.designType}</td>
                        <td>{design.componentsDescription}</td>
                        <td>{design.numberOfImplementations}</td>
                        <td>
                          <FaEdit
                            className={selectedDesign === design.designType ? 'text-success' : 'text-muted'}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDesignSelection(design)}
                          />
                        </td>
                        <td>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="radioNoLabel"
                            id={`radioNoLabel${design.designId}`}
                            value={design.designId}
                            onChange={() => setSelectedDesignId(design.designId)}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">No designs available</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="d-flex justify-content-end mx-2 mt-3">
                <Button variant="primary"className="mx-2" onClick={handleOpenPopup}>Add Design</Button>
                <Button variant="success" onClick={handleNextClick}>Next</Button>
              </div>
            </div>
          )}

          {activeTab === 'designs' && designPageView === 'detail' && (
            <div className="mt-4 main">
              <h2 className="text-center">Design Versions</h2>
              <Table bordered>
                <thead className="bg-primary text-white">
                  <tr>
                    <th>Description</th>
                    <th>Inverter</th>
                    <th>Battery</th>
                    <th>Panels</th>
                    <th>PV Power</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {designVersions.length > 0 ? (
                    designVersions.map((version) => (
                      <tr key={version.versionId}>
                        <td>{version.description}</td>
                        <td>{version.inverter}</td>
                        <td>{version.battery}</td>
                        <td>{version.panels}</td>
                        <td>{version.pvPower}</td>
                        <td>{version.status.description}</td>
                        <td>
                          <FaEdit 
                            className="text-muted" 
                            style={{ cursor: 'pointer' }} 
                            onClick={() => {
                              setSelectedVersionId(version.versionId);
                              setIsGridTiedWBatteryPopupOpen(true);
                            }}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">No versions available</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="d-flex justify-content-between mt-3">
                <Button variant="secondary" onClick={handlePrevClick}>Prev</Button>
                <Button variant="primary">Add Version</Button>
                <Button variant="success">Finish</Button>
              </div>
            </div>
          )}

          {activeTab === 'components' && (
            <div className="mt-4 main">
              <h2 className="text-center">Components</h2>
              <Table bordered>
                <thead className="bg-primary text-white">
                  <tr>
                    <th>Type</th>
                    <th>Brand</th>
                    <th>Model</th>
                    <th>Power</th>
                    <th>Image URL</th>
                    <th>Datasheet</th>
                    <th>Details</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {components.length > 0 ? (
                    components.map((component) => (
                      <tr key={component.componentId}>
                        <td>{component.type}</td>
                        <td>{component.brand}</td>
                        <td>{component.model}</td>
                        <td>{component.power}</td>
                        <td>{component.imageUrl || 'N/A'}</td>
                        <td>{component.datasheet || 'N/A'}</td>
                        <td>
                          <a
                            href="#"
                            onClick={() => handleComponentDetails(component)}
                          >
                            View Details
                          </a>
                        </td>
                        <td>
                          <FaEdit
                            className="text-muted"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleEditComponent(component)}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">No components available</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="d-flex justify-content-end mt-3">
                <Button variant="primary" className="mx-2" onClick={handleOpenAddComponentPopup}>Add Component</Button>

                <Button variant="success">Finish</Button>
              </div>
            </div>
          )}

          {activeTab === 'vendors' && (
            <div className="mt-4 main">
              <h2 className="text-center">Vendors</h2>
              <p className="text-center">Please provide the information of the new vendor</p>

          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Vendor Name:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter vendor name"
                  name="name"
                  value={newVendor.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Website:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter website URL"
                  name="website"
                  value={newVendor.website}
                  onChange={handleInputChange}
                />
              </div>
              <Button variant="primary" onClick={handleVendorSave}>Save</Button>
            </div>


                 <div className="col-md-6">
                <h4 className="text-center">Current Vendors:</h4>
                <select className="form-select" size="7">
                  {vendors.length > 0 ? (
                    vendors.map((vendor) => (
                      <option key={vendor.vendorId}>{vendor.name}</option>
                    ))
                  ) : (
                    <option>No vendors available</option>
                  )}
                </select>
              </div>
              </div>
            </div>
          )}
        </div>
      </section>

      {isPopupOpen && (
        <DesignPopup
          design={selectedDesignDetails}
          components={componentsData}
          onClose={handleClosePopup}
        />
      )}

      {showPopup && (
        <ComponentPopup 
          component={selectedComponent}  
          onClose={() => setShowPopup(false)} 
        />
      )}

      {isComponentDetailsOpen && (
        <ComponentDetails
          component={selectedComponent}
          onClose={handleCloseComponentDetails} 
        />
      )}

      {isGridTiedWBatteryPopupOpen && (
        <GridTiedWBatteryPopup 
          versionId={selectedVersionId}
          onClose={handleClosePopup} 
        />
      )}
      
      {showDesignPopup && (
        <AddDesign
          design={solarDesigns}
          onClose={handleClosePopup}
        />
      )}

{showAddComponentPopup && (
  <AddComponent
    component={selectedComponent} 
    onClose={handleCloseAddComponentPopup} 
  />
)}

    </>
  );
}

export default AdminPage;
