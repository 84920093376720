// import React, { useState, useEffect } from 'react';
// import { Button, Form, Row, Col } from 'react-bootstrap';
// import VendorSelectionModal from './ VendorSelectionModal.jsx';
// import { fetchComponentTypes, fetchVendors } from '../../pages/Admin/apiService.jsx';

// function ComponentPopup({ component, onClose }) {
//   const [showVendorModal, setShowVendorModal] = useState(false);
//   const [componentTypes, setComponentTypes] = useState([]);
//   const [vendors, setVendors] = useState([]);
//   const [selectedVendor, setSelectedVendor] = useState(null);

//   useEffect(() => {
//     const getComponentTypes = async () => {
//       try {
//         const data = await fetchComponentTypes();
//         setComponentTypes(data);
//       } catch (error) {
//         console.error('Error fetching component types:', error);
//       }
//     };
//     getComponentTypes();
//   }, []);

//   const handleSetVendor = async () => {
//     try {
//       const fetchedVendors = await fetchVendors();
//       setVendors(fetchedVendors.map(vendor => vendor.name)); 
//       setShowVendorModal(true);
//     } catch (error) {
//       console.error('Error fetching vendors:', error);
//     }
//   };

//   const handleVendorSelection = (vendor) => {
//     setSelectedVendor(vendor);
//     // Do not close the modal here
//   };

//   return (
//     <div className="custom-popup-overlay">
//       <div className="custom-popup-content p-4">
//         <button className="custom-close-button" onClick={onClose}>
//           &times;
//         </button>
//         <h2 className="custom-text-center custom-font-weight-bold">Components</h2>
//         <p className="custom-text-center">Please modify the component</p>

//         <Form>
//           <Row>
//             <Col md={6}>
//               <Form.Group>
//                 <Form.Label>Select the type of component</Form.Label>
//                 <div className="custom-select-wrapper">
//                   <Form.Control as="select" defaultValue={component?.type || "Hybrid Inverter"}>
//                     {componentTypes.map((typeObj, index) => (
//                       <option key={index} value={typeObj.type}>
//                         {typeObj.type}
//                       </option>
//                     ))}
//                   </Form.Control>
//                 </div>
//               </Form.Group>
//               <Button variant="outline-success">Select datasheet ...</Button>
//             </Col>

//             <Col md={6}>
//               <Form.Group>
//                 <Form.Label>Brand</Form.Label>
//                 <Form.Control type="text" defaultValue={component?.brand || ""} />
//               </Form.Group>
//               <Button variant="outline-success">Select image ...</Button>
//             </Col>
//           </Row>

//           <Row className="mt-3">
//             <Col md={6}>
//               <Form.Group>
//                 <Form.Label>Model</Form.Label>
//                 <Form.Control type="text" defaultValue={component?.model || ""} />
//               </Form.Group>
//             </Col>

//             <Col md={6}>
//               <Form.Group>
//                 <Form.Label>Vendors</Form.Label>
//                 <Form.Control
//                   as="select"
//                   multiple
//                   value={selectedVendor ? [selectedVendor] : []}
//                   className="custom-vendor-select"
//                 >
//                   {vendors.map((vendor, index) => (
//                     <option key={index} value={vendor}>{vendor}</option>
//                   ))}
//                 </Form.Control>
//               </Form.Group>
//               <Button
//                 variant="primary"
//                 className="custom-set-vendor-btn mr-2 mx-2"
//                 onClick={handleSetVendor}
//               >
//                 Set vendor
//               </Button>
//               <Button variant="danger" className="custom-remove-vendor-btn">
//                 Remove vendor
//               </Button>
//             </Col>
//           </Row>

//           <div className="custom-highlight-box p-3 mt-3">
//             <p>This area will show some controls depending on the selected component type.</p>
//           </div>

//           <div className="custom-text-center mt-4">
//             <Button variant="primary" className="px-5">Save</Button>
//           </div>
//         </Form>

//         {/* Vendor Selection Modal */}
//         <VendorSelectionModal
//           show={showVendorModal}
//           onHide={() => setShowVendorModal(false)}
//           vendors={vendors}
//           onSelectVendor={handleVendorSelection}
//         />
//       </div>
//     </div>
//   );
// }

// export default ComponentPopup;


import React, { useState} from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import VendorSelectionModal from './ VendorSelectionModal.jsx';
import { fetchVendors } from '../../pages/Admin/apiService.jsx';

function ComponentPopup({ component, onClose }) {
  const [showVendorModal, setShowVendorModal] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);

  const handleSetVendor = async () => {
    try {
      const fetchedVendors = await fetchVendors();
      setVendors(fetchedVendors.map(vendor => vendor.name)); 
      setShowVendorModal(true);
    } catch (error) {
      console.error('Error fetching vendors:', error);
    }
  };

  const handleVendorSelection = (vendor) => {
    setSelectedVendor(vendor);
  };

  return (
    <div className="custom-popup-overlay">
      <div className="custom-popup-content p-4">
        <button className="custom-close-button" onClick={onClose}>
          &times;
        </button>
        <h2 className="custom-text-center custom-font-weight-bold">Components</h2>
        <p className="custom-text-center">Please modify the component</p>

        <Form>
          <Row>
          <Col md={6}>
              <Form.Group>
                <Form.Label>Select the type of component</Form.Label>
                <Form.Control as="select" defaultValue={component?.type || "Hybrid Inverter"}>
                   <option>PV</option>
                  <option>ISD</option>
                   <option>Hybrid Inverter</option>
                 </Form.Control>
               </Form.Group>
              <Button variant="outline-success">Select datasheet ...</Button>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Brand</Form.Label>
                <Form.Control type="text" defaultValue={component?.brand || ""} />
              </Form.Group>
              <Button variant="outline-success">Select image ...</Button>
            </Col>
            </Row>
            <Row className="mt-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Model</Form.Label>
                <Form.Control type="text" defaultValue={component?.model || ""} />
              </Form.Group>
            </Col>
                      <Col md={6}>
              <Form.Group>
                <Form.Label>Vendors</Form.Label>
                <Form.Control
                  as="select"
                  multiple
                  value={selectedVendor ? [selectedVendor] : []}
                  className="custom-vendor-select"
                >
                  {vendors.map((vendor, index) => (
                    <option key={index} value={vendor}>{vendor}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Button
                variant="primary"
                className="custom-set-vendor-btn mr-2 mx-2"
                onClick={handleSetVendor}
              >
                Set vendor
              </Button>
              <Button variant="danger" className="custom-remove-vendor-btn">
                Remove vendor
              </Button>
            </Col>
          </Row>

          <div className="custom-highlight-box p-3 mt-3">
            <p>This area will show some controls depending on the selected component type.</p>
          </div>

          <div className="custom-text-center mt-4">
            <Button variant="primary" className="px-5">Save</Button>
          </div>
        </Form>

        {/* Vendor Selection Modal */}
        <VendorSelectionModal
          show={showVendorModal}
          onHide={() => setShowVendorModal(false)}
          vendors={vendors}
          onSelectVendor={handleVendorSelection}
        />
      </div>
    </div>
  );
}

export default ComponentPopup;
